/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import arrowIcon from '../../../../assets/icons/arrow-expand.svg';

import { PAYMENTS_SWITCH_OPTIONS } from '../../../../constants/main';
import useCheckDesktopScreen from '../../../../hooks/useCheckDesktopScreen';
import RoundButtonWithIcon from '../../../UI/Buttons/RoundButtonWithIcon';
import classes from './styles.module.scss';

const AMOUNT_COLOR = {
  [PAYMENTS_SWITCH_OPTIONS.GETTING_PAID]: '#4BD2B1',
  [PAYMENTS_SWITCH_OPTIONS.PAYMENTS]: 'rgba(219, 40, 40, 0.63)',
};

const BillingHistoryItem = ({ data }) => {
  const history = useHistory();
  const isDesktop = useCheckDesktopScreen();

  const { type, amount, Engagement } = data || {};

  const formattedAmount =
    type === PAYMENTS_SWITCH_OPTIONS.GETTING_PAID
      ? `+$${amount}`
      : `-$${amount}`;

  const handleOpenEngagement = useCallback(() => {
    history.push(`/engagements/${Engagement.id}`);
  }, [Engagement.id, history]);

  return (
    <li
      onClick={() => {
        if (!isDesktop) {
          handleOpenEngagement();
        }
      }}
      className={classes.BillingHistoryItem}
    >
      <div className={classes.paymentLog}>
        <span className={classes.title}>{Engagement.title}</span>
        <span className={classes.amount} style={{ color: AMOUNT_COLOR[type] }}>
          {formattedAmount}
        </span>
      </div>

      <RoundButtonWithIcon
        onClick={handleOpenEngagement}
        classnames={[classes.openEngagementButton]}
        icon={arrowIcon}
      />
    </li>
  );
};

export default BillingHistoryItem;
