export default function formatNumberWithIndentation(number) {
  const formattedNumber = number.toLocaleString('en-US', {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const indentedNumber = formattedNumber.split(',').join(' ');

  return indentedNumber;
}
