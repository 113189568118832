import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import backIcon from '../../../../assets/icons/arrow-back.svg';

import RoundButtonWithIcon from '../RoundButtonWithIcon';
import classes from './styles.module.scss';

const BackButton = ({ label, to }) => {
  const history = useHistory();

  const handleBackClick = useCallback(() => {
    if (to) {
      history.push(to);
    } else {
      history.goBack();
    }
  }, [history, to]);

  return (
    <div className={classes.BackButton}>
      <RoundButtonWithIcon
        onClick={handleBackClick}
        icon={backIcon}
        iconAlt="Back"
      />
      <span className={classes.label}>{label}</span>
    </div>
  );
};

export default BackButton;
