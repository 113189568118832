/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';

import cn from 'classnames';

import Avatar from '../../../UI/Avatar';
import classes from './styles.module.scss';

const CustomerOption = ({ item, handleItemClick, isSelected }) => {
  const handleItemSelect = useCallback(() => {
    if (!isSelected) {
      handleItemClick(item);
    }
  }, [item, isSelected, handleItemClick]);

  return (
    <li
      onClick={handleItemSelect}
      key={item.id}
      className={cn(classes.CustomerOption, {
        [classes.selected]: isSelected,
      })}
    >
      <Avatar imagePath={item.avatarPath} alt={item.name} />
      <div className={classes.infoContainer}>
        <span className={classes.name}>{item.name}</span>
        <span className={classes.email}>{item.email}</span>
      </div>
    </li>
  );
};

export default CustomerOption;
