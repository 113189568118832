import React from 'react';

import HiresItem from './HiresItem';
import classes from './styles.module.scss';

const HiresList = ({
  hires,
  handleDeleteEngagementUser,
  handleUpdateEngagementUser,
  isEngagementOpen,
}) => {
  let content = <span className={classes.noHires}>Currently No Hires</span>;

  if (hires.length) {
    content = hires.map((hire) => (
      <HiresItem
        key={hire.id}
        hire={hire}
        handleDeleteEngagementUser={handleDeleteEngagementUser}
        handleUpdateEngagementUser={handleUpdateEngagementUser}
        isEngagementOpen={isEngagementOpen}
      />
    ));
  }

  return (
    <div className={classes.HiresList}>
      <span className={classes.title}>Hires:</span>
      {content}
    </div>
  );
};

export default HiresList;
