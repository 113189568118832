import React from 'react';

import cn from 'classnames';

import classes from './styles.module.scss';

const VARIANTS = {
  OUTLINE: 'outline',
};

const ICON_NAMES = {
  ARROW: 'arrow',
  LOGOUT: 'logout',
  CHECKMARK: 'checkmark',
};

const PrimaryButton = ({
  children,
  classnames = [],
  iconName,
  variant,
  ...rest
}) => {
  return (
    <button
      className={cn(
        classes.PrimaryButton,
        {
          [classes.outline]: variant === VARIANTS.OUTLINE,
          [classes.icon]: iconName,
          [classes.iconArrow]: iconName === ICON_NAMES.ARROW,
          [classes.iconLogout]: iconName === ICON_NAMES.LOGOUT,
          [classes.iconCheckmark]: iconName === ICON_NAMES.CHECKMARK,
        },
        ...classnames
      )}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
