import React from 'react';

import cn from 'classnames';

import logo from '../../assets/images/logo.svg';
import randomPerson from '../../assets/images/random-person.png';

import classes from './styles.module.scss';

const LoginContainer = ({ children, variant }) => {
  return (
    <div
      className={cn(classes.LoginContainer, {
        [classes.admin]: variant === 'admin',
      })}
    >
      <header className={classes.header}>
        <img src={logo} alt="LevelDo" />
      </header>

      <main className={classes.content}>
        <img
          src={randomPerson}
          alt="Random person"
          className={classes.personImage}
        />
        {children}
      </main>
    </div>
  );
};

export default LoginContainer;
