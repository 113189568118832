import React, { useCallback, useState, useRef, useEffect } from 'react';

import cn from 'classnames';

import plusIcon from '../../../../assets/icons/plus.svg';
import checkmarkIcon from '../../../../assets/icons/checkmark.svg';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import RoundButtonWithIcon from '../../../UI/Buttons/RoundButtonWithIcon';
import DropdownList from '../../../UI/Dropdown/DropdownList';
import HiresOption from './HiresOption';
import Search from '../../../UI/Dropdown/Search';
import classes from './styles.module.scss';

const AddHiresDropdown = ({
  items,
  selectedItem,
  onResetSelectedItem,
  label,
  onItemClick,
  searchValue,
  onSearch,
  clearSearchValue,
  onSubmit,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [clientRate, setClientRate] = useState('');
  const [serviceProviderRate, setServiceProviderRate] = useState('');
  const [maxHours, setMaxHours] = useState('');
  const [responsibilities, setResponsibilities] = useState('');

  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  useOnClickOutside(dropdownRef, () => {
    setIsDropdownOpen(false);
    onResetSelectedItem();
  });

  useEffect(() => {
    setServiceProviderRate(selectedItem?.rate || '');
  }, [selectedItem]);

  const handleDropdownToggle = useCallback(() => {
    if (!isDropdownOpen) {
      setTimeout(() => searchInputRef.current.focus());
    }

    if (!isDropdownOpen) {
      clearSearchValue();
    }

    setIsDropdownOpen((prevState) => !prevState);
  }, [isDropdownOpen, clearSearchValue]);

  const handleItemClick = useCallback(
    (item) => {
      onItemClick(item);
      setIsDropdownOpen(false);
    },
    [onItemClick]
  );

  const handleClientRateChange = useCallback((event) => {
    const { value } = event.target;

    setClientRate(value);
  }, []);

  const handleServiceProviderRateChange = useCallback((event) => {
    const { value } = event.target;

    setServiceProviderRate(value);
  }, []);

  const handleMaxHoursChange = useCallback((event) => {
    const { value } = event.target;

    setMaxHours(value);
  }, []);

  const handleResponsibilitiesChange = useCallback((event) => {
    const { value } = event.target;

    setResponsibilities(value);
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.stopPropagation();

      onSubmit({ clientRate, serviceProviderRate, maxHours, responsibilities });
      setClientRate('');
      setServiceProviderRate('');
      setMaxHours('');
      setResponsibilities('');
      setIsDropdownOpen(false);
    },
    [onSubmit, clientRate, maxHours, serviceProviderRate, responsibilities]
  );

  const isSubmitDisabled =
    !clientRate || !serviceProviderRate || !maxHours || !responsibilities;

  let dropdownButton;

  if (selectedItem) {
    dropdownButton = (
      <RoundButtonWithIcon
        onClick={handleSubmit}
        classnames={[classes.button]}
        icon={checkmarkIcon}
        iconAlt="Submit"
        disabled={isSubmitDisabled}
      />
    );
  } else {
    dropdownButton = (
      <RoundButtonWithIcon
        onClick={handleDropdownToggle}
        classnames={[classes.button]}
        icon={plusIcon}
        iconAlt="Add"
      />
    );
  }

  let dropdownButtonContent = <span>{label}</span>;

  if (selectedItem) {
    dropdownButtonContent = (
      <HiresOption
        item={selectedItem}
        isSelected
        clientRate={clientRate}
        serviceProviderRate={serviceProviderRate}
        maxHours={maxHours}
        responsibilities={responsibilities}
        handleClientRateChange={handleClientRateChange}
        handleServiceProviderRateChange={handleServiceProviderRateChange}
        handleMaxHoursChange={handleMaxHoursChange}
        handleResponsibilitiesChange={handleResponsibilitiesChange}
      />
    );
  }

  if (isDropdownOpen) {
    dropdownButtonContent = (
      <Search
        value={searchValue}
        onSearch={onSearch}
        searchInputRef={searchInputRef}
        backgroundColor="#F7F7F7"
      />
    );
  }

  return (
    <div
      ref={dropdownRef}
      className={cn(classes.AddHiresDropdown, {
        [classes.open]: isDropdownOpen,
        [classes.selected]: selectedItem && !isDropdownOpen,
      })}
    >
      <div className={classes.dropdownToggle}>
        {dropdownButtonContent}
        {dropdownButton}
      </div>
      <DropdownList
        items={items}
        handleItemClick={handleItemClick}
        isDropdownOpen={isDropdownOpen}
        dropdownItem={HiresOption}
        classnames={[classes.list]}
      />
    </div>
  );
};

export default AddHiresDropdown;
