import React, { useEffect, useRef } from 'react';

import Input from '../../../../../UI/Input';
import classes from './styles.module.scss';

const Rate = ({ title, isEditable, newValue, onChange, children }) => {
  const inputContainerRef = useRef(null);

  useEffect(() => {
    if (title) {
      inputContainerRef.current?.style.setProperty('--title', `"${title}"`);
    }
  }, [title, inputContainerRef, isEditable]);

  let content;

  if (isEditable) {
    content = (
      <div className={classes.inputContainer} ref={inputContainerRef}>
        <Input
          value={newValue || ''}
          onChange={onChange}
          type="number"
          // placeholder={title}
          classnames={[classes.input]}
        />
      </div>
    );
  } else {
    content = (
      <>
        <span className={classes.title}>{title}:</span>
        <span className={classes.content}>{children}</span>
      </>
    );
  }

  return <div className={classes.Rate}>{content}</div>;
};

export default Rate;
