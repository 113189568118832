import { useCallback, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import useUser from './useUser';
import { logout } from '../helpers/globalAuth';
import { AuthContext } from '../context/AuthContext';

const useLogout = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { setAuth } = useContext(AuthContext);

  const { currentUser, isAdmin } = useUser();

  return useCallback(async () => {
    const redirectPath = isAdmin ? '/admin-login' : '/login';
    setAuth(null);

    try {
      logout(() => {
        history.push(redirectPath);
        queryClient.removeQueries();
      });
    } catch (error) {
      console.log(error);
    }
  }, [history, queryClient, currentUser, isAdmin]);
};

export default useLogout;
