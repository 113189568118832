import React, { useRef, useState } from 'react';

import cn from 'classnames';

import infoIcon from '../../../assets/icons/info.svg';
import activeInfoIcon from '../../../assets/icons/info-active.svg';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import Tooltip from '../Tooltip';
import classes from './styles.module.scss';

const InfoIcon = ({ className, text, withTooltip }) => {
  const [isActive, setIsActive] = useState(false);

  const infoIconRef = useRef(null);
  useOnClickOutside(infoIconRef, () => setIsActive(false));

  const toggleActive = () => setIsActive((prevState) => !prevState);

  const icon = isActive && withTooltip ? activeInfoIcon : infoIcon;

  return (
    <div
      onClick={toggleActive}
      ref={infoIconRef}
      className={cn(classes.InfoIcon, className)}
      style={{
        cursor: withTooltip ? 'pointer' : 'default',
      }}
    >
      {withTooltip ? (
        <Tooltip isTooltipVisible={isActive} text={text}>
          <img src={icon} alt="Info" />
        </Tooltip>
      ) : (
        <img src={icon} alt="Info" />
      )}
    </div>
  );
};

export default InfoIcon;
