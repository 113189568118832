// import axios from 'axios';
import { axiosPrivate } from '../api/axios';

const EngagementService = {
  async createEngagement({ title, description, customerId }) {
    const response = await axiosPrivate.post('/engagements', {
      title,
      description,
      customerId,
    });

    return response.data;
  },
  async getEngagements({ page, limit, search, type, statuses }) {
    const response = await axiosPrivate.get('/engagements', {
      params: {
        page,
        limit,
        search,
        type,
        statuses: JSON.stringify(statuses),
      },
    });

    return response.data;
  },
  async getEngagementById(id) {
    const response = await axiosPrivate.get(`/engagements/${id}`);

    return response.data;
  },
  async updateEngagement({
    id,
    title,
    description,
    status,
    customerId,
    userId,
  }) {
    const response = await axiosPrivate.patch(`/engagements/${id}`, {
      title,
      description,
      status,
      customerId,
      userId,
    });

    return response.data;
  },
  async deleteEngagement(id) {
    const response = await axiosPrivate.delete(`/engagements/${id}`);

    return response.data;
  },
  async createEngagementUser({
    id,
    userId,
    clientRate,
    serviceProviderRate,
    maxHours,
    responsibilities,
  }) {
    const response = await axiosPrivate.post(`/engagements/${id}/hires`, {
      userId,
      clientRate,
      serviceProviderRate,
      maxHours,
      responsibilities,
    });

    return response.data;
  },
  async deleteEngagementUser({ id }) {
    const response = await axiosPrivate.delete(`/engagements/hires/${id}`);

    return response.data;
  },
  async updateEngagementUser({
    id,
    clientRate,
    serviceProviderRate,
    maxHours,
    responsibilities,
    status,
    hours,
  }) {
    const response = await axiosPrivate.patch(`/engagements/hires/${id}`, {
      clientRate,
      serviceProviderRate,
      maxHours,
      responsibilities,
      status,
      hours,
    });

    return response.data;
  },
  async addHoursToEngagementUser({ id, hours }) {
    try {
      const response = await axiosPrivate.put(
        `/engagements/hires/${id}/hours`,
        {
          hours: +hours,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default EngagementService;
