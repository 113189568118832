import React, { useState, useEffect, useCallback } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ModalContainer from '../ModalContainer';
import PrimaryButton from '../../components/UI/Buttons/PrimaryButton';
import classes from './styles.module.scss';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote'],

  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }],

  [{ size: ['small', false, 'large', 'huge'] }],

  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ['link', 'image'],

  ['clean'],
];

const EditorModal = ({ show, handleClose, handleConfirm, html, setHtml }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (show) {
      setValue('');
    }
  }, [show]);

  useEffect(() => {
    if (!value) {
      setValue(html);
      return;
    }

    setHtml(value);
  }, [html, setHtml, value]);

  const handleSave = useCallback(() => {
    handleConfirm(value);
    handleClose();
  });

  return (
    <ModalContainer
      show={show}
      handleClose={handleClose}
      contentClassName={classes.EditorModal}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          modules={{ toolbar: toolbarOptions }}
          style={{ width: '100%', height: '100%' }}
        />
        <PrimaryButton
          onClick={handleSave}
          type="button"
          iconName="arrow"
          classnames={[classes.saveButton]}
        >
          Save
        </PrimaryButton>
      </div>
    </ModalContainer>
  );
};

export default EditorModal;
