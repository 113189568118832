import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from '../api/axios';

const useRefreshToken = () => {
  const { setAuth } = useContext(AuthContext);

  const refresh = async () => {
    const response = await axios.post('/auth-token-refresh');
    const { accessToken } = response.data;
    setAuth({ accessToken });
    return accessToken;
  };

  return refresh;
};

export default useRefreshToken;
