import React from 'react';

import clockIcon from '../../../../../../assets/icons/clock.svg';

import classes from './styles.module.scss';

const TotalHours = ({ hours }) => {
  return (
    <div className={classes.TotalHours}>
      <span className={classes.title}>Total hours:</span>
      <span className={classes.content}>
        <img src={clockIcon} alt="Hours" /> {Math.round(hours)}
      </span>
    </div>
  );
};

export default TotalHours;
