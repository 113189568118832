import React, { useContext, useEffect, useState } from 'react';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import cn from 'classnames';

import addCardIcon from '../../../assets/icons/add-card.svg';
import cardIcon from '../../../assets/icons/card.svg';
import whiteCardIcon from '../../../assets/icons/card-white.svg';

import formatNumberWithIndentation from '../../../helpers/formatNumberWithIndentation';
import useUser from '../../../hooks/useUser';
import PrimaryButton from '../../UI/Buttons/PrimaryButton';
import classes from './styles.module.scss';
import { UiContext } from '../../../context/UiContext';

const CARD_STATUS = {
  EMPTY: 'empty',
  FILLING: 'filling',
  FILLED: 'filled',
  LOADING: 'loading',
};

const renderDotsGroup = () => (
  <div key={Math.random()} className={classes.dotsGroup}>
    {[...Array(4)].map(() => (
      <span key={Math.random()} className={classes.dot} />
    ))}
  </div>
);

const Card = ({
  cardDetails,
  balanceAmount,
  className,
  onSubmit,
  onUpdate,
}) => {
  const [cardStatus, setCardStatus] = useState(CARD_STATUS.EMPTY);
  const { showErrorModal } = useContext(UiContext);

  const { isServiceProvider } = useUser();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (cardDetails) {
      setCardStatus(CARD_STATUS.FILLED);
    }
  }, [cardDetails]);

  const handleSubmit = async () => {
    const cardNumberElement = elements.getElement('cardNumber');
    const { token } = await stripe.createToken(cardNumberElement);

    try {
      setCardStatus(CARD_STATUS.LOADING);
      await onSubmit(token.id);
      setCardStatus(CARD_STATUS.FILLED);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async () => {
    const cardNumberElement = elements.getElement('cardNumber');
    if (!cardNumberElement) {
      setCardStatus(CARD_STATUS.FILLING);
      return;
    }

    try {
      const { token } = await stripe.createToken(cardNumberElement);
      if (!token) {
        throw new Error('Incorect input. Please, try again.');
      }

      setCardStatus(CARD_STATUS.LOADING);
      await onUpdate(token.id);
      setCardStatus(CARD_STATUS.FILLED);
    } catch (error) {
      console.log(error);
      showErrorModal({
        message: error.message,
      });
    }
  };

  let content;

  switch (cardStatus) {
    case CARD_STATUS.EMPTY:
      content = (
        <div
          onClick={() => setCardStatus(CARD_STATUS.FILLING)}
          className={classes.empty}
          type="button"
        >
          <img src={addCardIcon} alt="Add card" />
          <span>Add card</span>
        </div>
      );
      break;

    case CARD_STATUS.FILLING:
      content = (
        <div className={classes.filling}>
          <div className={classes.top}>
            <CardNumberElement className={classes.cardNumberElement} />
            <img src={cardIcon} alt="Card" />
          </div>
          <div className={classes.bottom}>
            <CardExpiryElement className={classes.cvcAndExpiresElement} />
            <CardCvcElement className={classes.cvcAndExpiresElement} />
          </div>
        </div>
      );
      break;

    case CARD_STATUS.FILLED:
      content = (
        <div className={classes.filled}>
          {isServiceProvider && (
            <>
              <span className={classes.currentBalance}>Current Balance</span>
              <span className={classes.balance}>
                $
                {Number.isNaN(balanceAmount)
                  ? 0
                  : formatNumberWithIndentation(balanceAmount)}
              </span>
            </>
          )}
          <div className={classes.cardNumber}>
            {[...Array(3)].map(() => renderDotsGroup())}
            {cardDetails?.last4}
            <img src={whiteCardIcon} alt="Card" />
          </div>
        </div>
      );
      break;

    case CARD_STATUS.LOADING:
      content = (
        <div className={classes.loading}>
          <span>Loading...</span>
        </div>
      );
      break;

    default:
      break;
  }

  return (
    <div className={cn(classes.Card, className)}>
      <div
        className={cn(classes.innerCard, {
          [classes.filled]: cardStatus === CARD_STATUS.FILLED,
        })}
      >
        {content}
      </div>
      {cardStatus === CARD_STATUS.FILLING && !cardDetails && (
        <PrimaryButton
          onClick={handleSubmit}
          classnames={[classes.createButton]}
          iconName="checkmark"
        >
          Add card
        </PrimaryButton>
      )}
      {cardDetails && (
        <PrimaryButton
          onClick={handleUpdate}
          classnames={[classes.createButton]}
          iconName="checkmark"
        >
          Edit card
        </PrimaryButton>
      )}
    </div>
  );
};

export default Card;
