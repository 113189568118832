import React from 'react';

import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import 'react-quill/dist/quill.core.css';

import UserService from '../../services/UserService';

const PublicProfilePage = () => {
  const { id } = useParams();

  const { data: user } = useQuery('user', () => UserService.getUserById(id));

  return (
    <div
      className="view ql-editor"
      dangerouslySetInnerHTML={{ __html: user?.details }}
    />
  );
};

export default PublicProfilePage;
