import React, { useCallback } from 'react';

import ModalContainer from '../ModalContainer';
import PrimaryButton from '../../components/UI/Buttons/PrimaryButton';
import classes from './styles.module.scss';

const MODAL_TYPE = {
  ENGAGEMENT: 'engagement',
  USER: 'user',
};

const DeleteModal = ({ show, handleClose, handleConfirm, data, type }) => {
  const handleDelete = useCallback(() => {
    handleConfirm(data?.id);
    handleClose();
  }, [handleConfirm, handleClose]);

  const displayProperty = {
    [MODAL_TYPE.ENGAGEMENT]: data?.title,
    [MODAL_TYPE.USER]: data?.name,
  };

  return (
    <ModalContainer
      show={show}
      handleClose={handleClose}
      contentClassName={classes.DeleteModal}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <h2 className={classes.title}>
          Do you want to delete {type} <br />
          <span className={classes.username}>{displayProperty[type]}</span>?
        </h2>

        <div className={classes.buttons}>
          <PrimaryButton onClick={handleClose} variant="outline">
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={handleDelete}>Delete</PrimaryButton>
        </div>
      </div>
    </ModalContainer>
  );
};

export default DeleteModal;
