/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useMemo, useState, useRef } from 'react';

import cn from 'classnames';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

const Status = ({
  value,
  valuesToShow,
  valueColors,
  handleUpdate,
  hasEditPermission,
  backgroundColor,
}) => {
  const [isStatusOpen, setIsStatusOpen] = useState(false);

  const statusRef = useRef(null);

  useOnClickOutside(statusRef, () => setIsStatusOpen(false));

  const handleStatusToggle = useCallback(() => {
    setIsStatusOpen((prevState) => !prevState);
  }, []);

  const handleStatusChange = useCallback((updatedStatus) => {
    handleUpdate(updatedStatus);
    setIsStatusOpen(false);
  }, []);

  const statusOptions = useMemo(() =>
    Object.values(valuesToShow).filter((status) => status !== value)
  );

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      ref={statusRef}
      className={cn(classes.Status, {
        [classes.open]: isStatusOpen,
        [classes.edit]: hasEditPermission,
      })}
    >
      <button
        onClick={handleStatusToggle}
        className={classes.statusButton}
        type="button"
        style={{
          background: hasEditPermission ? backgroundColor : 'transparent',
        }}
      >
        <div
          className={classes.mark}
          style={{ background: valueColors[value] }}
        />
        <span className={classes.status}>{value}</span>
      </button>

      <ul
        className={classes.statusList}
        style={{ background: backgroundColor }}
      >
        {statusOptions.map((status) => (
          <li
            onClick={() => handleStatusChange(status)}
            key={status}
            className={classes.statusItem}
          >
            <div
              className={classes.mark}
              style={{ background: valueColors[status] }}
            />
            <span className={classes.status}>{status}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Status;
