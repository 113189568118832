import React from 'react';

import ReactPaginate from 'react-paginate';

import classes from './styles.module.scss';

const Pagination = ({ pageCount, handlePageChange }) => {
  return (
    pageCount > 1 && (
      <ReactPaginate
        pageRangeDisplayed={1}
        pageCount={pageCount}
        onPageChange={handlePageChange}
        containerClassName={classes.Pagination}
        pageClassName={classes.page}
        pageLinkClassName={classes.pageLink}
        activeClassName={classes.activePage}
        activeLinkClassName={classes.activePageLink}
        previousClassName={classes.previous}
        nextClassName={classes.next}
        previousLinkClassName={classes.previousLink}
        nextLinkClassName={classes.nextLink}
        disabledClassName={classes.disabled}
        previousLabel=""
        nextLabel=""
        breakLabel=""
      />
    )
  );
};

export default Pagination;
