import React from 'react';

import cn from 'classnames';

import classes from './styles.module.scss';

const DropdownList = ({
  items,
  dropdownItem: DropdownItem,
  handleItemClick,
  isDropdownOpen,
  classnames = [],
}) => {
  return (
    <div
      className={cn(classes.DropdownList, ...classnames, {
        [classes.open]: isDropdownOpen,
      })}
    >
      <ul className={classes.items}>
        {items?.map((item) => (
          <DropdownItem
            key={item.id}
            item={item}
            handleItemClick={handleItemClick}
          />
        ))}
      </ul>
    </div>
  );
};

export default DropdownList;
