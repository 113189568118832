import { useCallback, useState } from 'react';

import useDebounce from './useDebounce';

const useSearchState = (initialValue = '') => {
  const [searchValue, setSearchValue] = useState(initialValue);

  const handleChange = useCallback((event) => {
    const { value } = event.target;

    setSearchValue(value);
  }, []);

  const clearSearchValue = useCallback(() => {
    setSearchValue('');
  }, []);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  return [searchValue, debouncedSearchValue, handleChange, clearSearchValue];
};

export default useSearchState;
