import React from 'react';

import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import CreateUserForm from '../../components/CreateOrEditUserForm';
import BackButton from '../../components/UI/Buttons/BackButton';
import classes from './styles.module.scss';

const CreateOrEditUserPage = () => {
  return (
    <div className={classes.CreateOrEditUserPage}>
      <HeaderMenuContainer isUserBadgeVisible>
        <BackButton label="Back to User Management" />
        <CreateUserForm />
      </HeaderMenuContainer>
    </div>
  );
};

export default CreateOrEditUserPage;
