import React from 'react';

import classes from './styles.module.scss';

const Search = ({ value, onSearch, searchInputRef, backgroundColor }) => {
  return (
    <div className={classes.Search}>
      <input
        value={value}
        onClick={(event) => event.stopPropagation()}
        onChange={onSearch}
        ref={searchInputRef}
        type="text"
        className={classes.searchInput}
        style={{ background: backgroundColor }}
      />
    </div>
  );
};

export default Search;
