import React from 'react';

import { useHistory } from 'react-router-dom';

import { ENGAGEMENTS_SWITCH_OPTIONS } from '../../constants/main';
import PrimaryButton from '../UI/Buttons/PrimaryButton';
import Search from './Search';
import Filter from './Filter';
import Switch from './Switch';
import classes from './styles.module.scss';

const Toolbar = ({
  title,
  createButtonLink,
  createButtonLabel,
  searchValue,
  filterOptions,
  handleSearchValueChange,
  onSwitchChange,
  selectedSwitchOption,
  hasFilter,
  hasSwitch,
  hasSearch,
  hasCreateButton,
  handleFilterOptionsChange,
  userRoles,
}) => {
  const history = useHistory();

  return (
    <div className={classes.Toolbar}>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.actions}>
        {hasSwitch && (
          <Switch
            customerOption={ENGAGEMENTS_SWITCH_OPTIONS.HIRES}
            providerOption={ENGAGEMENTS_SWITCH_OPTIONS.CUSTOMERS}
            onChange={onSwitchChange}
            selectedOption={selectedSwitchOption}
            userRoles={userRoles}
          />
        )}

        <div className={classes.buttons}>
          {hasFilter && (
            <Filter
              options={filterOptions}
              handleFilterOptionsChange={handleFilterOptionsChange}
            />
          )}

          {hasSearch && (
            <Search
              searchValue={searchValue}
              handleSearchValueChange={handleSearchValueChange}
            />
          )}
        </div>

        {hasCreateButton && (
          <PrimaryButton
            onClick={() => history.push(createButtonLink)}
            iconName="arrow"
            classnames={[classes.createButton]}
          >
            {createButtonLabel}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export default Toolbar;
