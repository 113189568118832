import React from 'react';

import Modal from 'react-bootstrap/Modal';

import classes from './styles.module.scss';

const ModalContainer = ({
  show,
  handleClose,
  children,
  dialogClassName,
  contentClassName,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName={contentClassName}
      dialogClassName={dialogClassName}
      backdropClassName={classes.backdrop}
    >
      {children}
    </Modal>
  );
};

export default ModalContainer;
