import React, { useState, useCallback, useEffect, useRef } from 'react';

import cn from 'classnames';

import checkmark from '../../../../assets/icons/checkmark.svg';

import Textarea from '../../../UI/Textarea';
import RoundButtonWithIcon from '../../../UI/Buttons/RoundButtonWithIcon';
import classes from './styles.module.scss';

const Description = ({
  description,
  isEditable,
  handleUpdate,
  isEngagementEditing,
  isEngagementOpen,
  isServiceProviderView,
}) => {
  const [newDescription, setNewDescription] = useState(description);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);

  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) {
      const element = descriptionRef.current;

      setIsOverflowing(element.offsetHeight < element.scrollHeight);
    }
  }, [descriptionRef, isReadMore, description]);

  useEffect(() => {
    if (!isEngagementEditing) {
      setNewDescription(description);
    }
  }, [isEngagementEditing, description]);

  const handleDescriptionChange = useCallback((event) => {
    const { value } = event.target;

    setNewDescription(value);
  }, []);

  let content;

  if (isEditable) {
    content = (
      <div className={classes.textareaContainer}>
        <Textarea
          value={newDescription}
          onChange={handleDescriptionChange}
          containerClassnames={[classes.textareaContainer]}
        />
        <RoundButtonWithIcon
          onClick={() => handleUpdate(newDescription)}
          classnames={[classes.descriptionButton]}
          icon={checkmark}
          iconAlt="Update description"
        />
      </div>
    );
  } else {
    content = (
      <>
        <span className={classes.title}>
          {isServiceProviderView ? 'Your engagement' : 'Description'}:
        </span>
        <div>
          <p
            className={cn(classes.text, {
              [classes.more]: isReadMore,
            })}
            ref={descriptionRef}
          >
            {description}
          </p>

          {isOverflowing && (
            <span
              onClick={() => setIsReadMore(true)}
              className={classes.readMore}
            >
              Read More
            </span>
          )}
        </div>
      </>
    );
  }

  return (
    <div
      className={cn(classes.Description, {
        [classes.open]: isEngagementOpen,
      })}
    >
      {content}
    </div>
  );
};

export default Description;
