import React from 'react';

import Status from '../../../Status';
import classes from './styles.module.scss';

const STATUS_VALUES = {
  WORKING: 'working',
  ON_HOLD: 'on hold',
  ENDED: 'ended',
};

const STATUS_COLORS = {
  [STATUS_VALUES.WORKING]: '#4BD2B1',
  [STATUS_VALUES.ON_HOLD]: 'rgba(135, 69, 218, 0.50)',
  [STATUS_VALUES.ENDED]: '#606161',
};

const STATUS_MAPPINGS = {
  [STATUS_VALUES.WORKING]: 'Working',
  [STATUS_VALUES.ON_HOLD]: 'On Hold',
  [STATUS_VALUES.ENDED]: 'Ended',
};

const EditableStatus = ({ status, isEditable, newStatus, handleUpdate }) => {
  return (
    <div className={classes.EditableStatus}>
      {isEditable ? (
        <Status
          value={newStatus}
          valuesToShow={STATUS_VALUES}
          valueColors={STATUS_COLORS}
          handleUpdate={handleUpdate}
          hasEditPermission
          backgroundColor="#ffffff"
        />
      ) : (
        <>
          <span className={classes.title}>Status:</span>
          <span
            className={classes.content}
            style={{ color: STATUS_COLORS[status] }}
          >
            {STATUS_MAPPINGS[status]}
          </span>
        </>
      )}
    </div>
  );
};

export default EditableStatus;
