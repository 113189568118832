import React from 'react';

import Input from '../../UI/Input';
import ErrorMessage from '../../UI/ErrorMessage';
import classes from './styles.module.scss';

const PasswordInputs = ({
  isPasswordVisible,
  onPasswordVisibilityToggle,
  passwordValue,
  confirmPasswordValue,
  onChange,
  onBlur,
  isPasswordTouched,
  isConfirmPasswordTouched,
  passwordError,
  confirmPasswordError,
}) => {
  return (
    <div className={classes.PasswordInputs}>
      {isPasswordVisible && (
        <>
          <div className={classes.inputContainer}>
            <Input
              value={passwordValue}
              name="password"
              onChange={onChange}
              onBlur={onBlur}
              placeholder="Password"
              classnames={[classes.input]}
              hasError={isPasswordTouched && passwordError}
            />
            {isPasswordTouched && passwordError && (
              <ErrorMessage message={passwordError} />
            )}
          </div>

          <div className={classes.inputContainer}>
            <Input
              value={confirmPasswordValue}
              name="confirmPassword"
              onChange={onChange}
              onBlur={onBlur}
              placeholder="Confirm Password"
              classnames={[classes.input]}
              hasError={isConfirmPasswordTouched && confirmPasswordError}
            />
            {isConfirmPasswordTouched && confirmPasswordError && (
              <ErrorMessage message={confirmPasswordError} />
            )}
          </div>
        </>
      )}

      {!isPasswordVisible && (
        <span
          onClick={onPasswordVisibilityToggle}
          className={classes.changePassword}
        >
          Change Password
        </span>
      )}
    </div>
  );
};

export default PasswordInputs;
