import { axiosPrivate } from '../api/axios';

const PageService = {
  async getPageByName({ name }) {
    const response = await axiosPrivate.get(`/pages/${name}`);

    return response.data;
  },

  async updatePage({ name, newContent }) {
    await axiosPrivate.patch(`/pages/${name}`, { content: newContent });
  },
};

export default PageService;
