import React, { useContext, useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { Redirect, Route, useHistory } from 'react-router-dom';

import UserService from '../../services/UserService';
import { AuthContext } from '../../context/AuthContext';
import useRefreshToken from '../../hooks/useRefreshToken';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth, persist } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (error) {
        history.push('/login');
      } finally {
        setIsLoading(false);
      }
    };
    if (!auth?.accessToken && persist) {
      verifyRefreshToken();
    } else {
      setIsLoading(false);
    }
  }, []);

  const { data: currentUser, isLoading: isUserLoading } = useQuery(
    'currentUser',
    UserService.getMe,
    {
      staleTime: Infinity,
      retry: 0,
    }
  );

  const hasRequiredRole = roles.some((role) =>
    currentUser?.roles?.includes(role)
  );

  if (isUserLoading || isLoading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        auth?.accessToken && hasRequiredRole ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
