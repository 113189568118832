import React from 'react';

import ModalContainer from '../ModalContainer';
import classes from './styles.module.scss';

const ErrorModal = ({ show, handleClose, message }) => {
  return (
    <ModalContainer
      show={show}
      handleClose={handleClose}
      contentClassName={classes.ErrorModal}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <p className={classes.message}>{message}</p>
      </div>
    </ModalContainer>
  );
};

export default ErrorModal;
