import React, { useState, useCallback } from 'react';

import cn from 'classnames';

import checkmarkIcon from '../../../../../assets/icons/checkmark.svg';
import whiteArrowIcon from '../../../../../assets/icons/arrow-white.svg';
import blueArrowIcon from '../../../../../assets/icons/arrow-blue.svg';
import mobileEditIcon from '../../../../../assets/icons/pen.svg';
import desktopEditIcon from '../../../../../assets/icons/pen-active.svg';
import deleteIcon from '../../../../../assets/icons/trash-blue.svg';

import useCheckDesktopScreen from '../../../../../hooks/useCheckDesktopScreen';
import useUser from '../../../../../hooks/useUser';
import RoundButtonWithIcon from '../../../../UI/Buttons/RoundButtonWithIcon';
import Avatar from '../../../../UI/Avatar';
import Rate from './Rate';
import EditableStatus from './EditableStatus';
import Responsibilities from './Responsibilities';
import TotalHours from './TotalHours';
import classes from './styles.module.scss';

const EDITABLE_FIELDS = {
  CLIENT_RATE: 'client rate',
  SERVICE_PROVIDER_RATE: 'service provider rate',
  MAX_HOURS: 'max hours',
  STATUS: 'status',
  RESPONSIBILITIES: 'responsibilities',
};

const HiresItem = ({
  hire,
  handleDeleteEngagementUser,
  handleUpdateEngagementUser,
  isEngagementOpen,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [editableFields, setEditableFields] = useState([]);
  const [newClientRate, setNewClientRate] = useState(hire.rate);
  const [newServiceProviderRate, setNewServiceProviderRate] = useState(
    hire.serviceProviderRate
  );
  const [newMaxHours, setNewMaxHours] = useState(hire.maxHours);
  const [newStatus, setNewStatus] = useState(hire.status);
  const [newResponsibilities, setNewResponsibilities] = useState(
    hire.responsibilities
  );

  const isDesktop = useCheckDesktopScreen();
  const { isAdmin, isProjectManager, isCustomer } = useUser();

  const handleExpand = useCallback(() => {
    if (isExpanded) {
      setEditableFields([]);
    }

    setIsExpanded((prevState) => !prevState);
  }, [isExpanded]);

  const handleEditToggle = useCallback(() => {
    setIsExpanded(true);

    setNewClientRate(hire.rate);
    setNewServiceProviderRate(hire.serviceProviderRate);
    setNewMaxHours(hire.maxHours);
    setNewStatus(hire.status);
    setNewResponsibilities(hire.responsibilities);

    setEditableFields((prevState) => {
      if (prevState.length) {
        return [];
      }

      return Object.values(EDITABLE_FIELDS);
    });
  }, [hire]);

  const handleEditSubmit = useCallback(() => {
    handleUpdateEngagementUser({
      id: hire.id,
      clientRate: newClientRate,
      serviceProviderRate: newServiceProviderRate,
      maxHours: newMaxHours,
      status: newStatus,
      responsibilities: newResponsibilities,
    });
    setIsExpanded(false);
    setEditableFields([]);
  }, [
    newClientRate,
    newStatus,
    newServiceProviderRate,
    newResponsibilities,
    newMaxHours,
    hire,
  ]);

  const { user } = hire;

  let arrowIcon;

  if (isDesktop) {
    arrowIcon = blueArrowIcon;
  } else {
    arrowIcon = isExpanded ? blueArrowIcon : whiteArrowIcon;
  }

  const isEditing = editableFields.length > 0;
  const isClientRateEditable = editableFields.includes(
    EDITABLE_FIELDS.CLIENT_RATE
  );
  const isMaxHoursEditable = editableFields.includes(EDITABLE_FIELDS.MAX_HOURS);
  const isStatusEditable = editableFields.includes(EDITABLE_FIELDS.STATUS);
  const isResponsibilitiesEditable = editableFields.includes(
    EDITABLE_FIELDS.RESPONSIBILITIES
  );
  const isServiceProviderRateEditable = editableFields.includes(
    EDITABLE_FIELDS.SERVICE_PROVIDER_RATE
  );

  const isEditSubmitDisabled =
    !newClientRate ||
    !newStatus ||
    !newResponsibilities ||
    !newMaxHours ||
    !newServiceProviderRate;

  const isServiceProviderRateVisible = isAdmin || isProjectManager;
  const isActionButtonsVisible =
    isEngagementOpen && (isAdmin || isProjectManager);

  let editButton;

  if (isEditing) {
    editButton = (
      <RoundButtonWithIcon
        onClick={handleEditSubmit}
        disabled={isEditSubmitDisabled}
        icon={checkmarkIcon}
        iconAlt="Save"
        iconSize={16}
        classnames={[classes.saveButton]}
      />
    );
  } else {
    editButton = (
      <RoundButtonWithIcon
        onClick={handleEditToggle}
        icon={isDesktop ? desktopEditIcon : mobileEditIcon}
        iconAlt="Edit"
        iconSize={16}
        classnames={[classes.editButton]}
      />
    );
  }

  return (
    <div
      key={hire.id}
      className={cn(classes.HiresItem, {
        [classes.expanded]: isExpanded,
        [classes.open]: isEngagementOpen,
        [classes.editing]: isEditing,
      })}
    >
      <div className={classes.infoContainer}>
        <div className={classes.top}>
          <Avatar imagePath={user.avatarPath} alt={hire.name} />
          <div className={classes.info}>
            <div className={cn(classes.col, classes.name)}>
              <span className={classes.title}>Hire:</span>
              <span className={classes.content}>{user.name}</span>
            </div>

            <Rate
              title={isCustomer ? 'Rate' : 'Client Rate'}
              isEditable={isClientRateEditable}
              newValue={newClientRate}
              onChange={(event) => setNewClientRate(event.target.value)}
            >
              ${hire.rate} / hour
            </Rate>

            {isDesktop && isServiceProviderRateVisible && (
              <Rate
                title="SP Rate"
                isEditable={isServiceProviderRateEditable}
                newValue={newServiceProviderRate}
                onChange={(event) =>
                  setNewServiceProviderRate(event.target.value)
                }
              >
                ${hire.serviceProviderRate} / hour
              </Rate>
            )}

            {isDesktop && (
              <Rate
                title="Max Hours"
                isEditable={isMaxHoursEditable}
                newValue={newMaxHours}
                onChange={(event) => setNewMaxHours(event.target.value)}
              >
                {hire.maxHours} / week
              </Rate>
            )}
            {isDesktop && !isEditing && <TotalHours hours={hire.totalHours} />}
            <div className={classes.statusContainer}>
              <EditableStatus
                status={hire.status}
                isEditable={isStatusEditable}
                newStatus={newStatus}
                handleUpdate={setNewStatus}
              />
            </div>
          </div>
        </div>

        <div className={cn(classes.info, classes.bottom)}>
          <div className={classes.ratesContainer}>
            <div className={classes.ratesInnerContainer}>
              {isServiceProviderRateVisible && (
                <Rate
                  title="SP Rate"
                  isEditable={isServiceProviderRateEditable}
                  newValue={newServiceProviderRate}
                  onChange={(event) =>
                    setNewServiceProviderRate(event.target.value)
                  }
                >
                  ${hire.serviceProviderRate} / hour
                </Rate>
              )}

              <Rate
                title="Max Hours"
                isEditable={isMaxHoursEditable}
                newValue={newMaxHours}
                onChange={(event) => setNewMaxHours(event.target.value)}
              >
                {hire.maxHours} / week
              </Rate>

              {!isEditing && <TotalHours hours={hire.totalHours} />}
            </div>

            <Responsibilities
              responsibilities={hire.responsibilities}
              isEditable={isResponsibilitiesEditable}
              newResponsibilities={newResponsibilities}
              handleChange={(event) =>
                setNewResponsibilities(event.target.value)
              }
              isBottom={isExpanded}
            />
          </div>

          <div className={classes.statusAndButtonsContainer}>
            <div className={classes.statusContainer}>
              <EditableStatus
                status={hire.status}
                isEditable={isStatusEditable}
                newStatus={newStatus}
                handleUpdate={setNewStatus}
              />
            </div>

            {isActionButtonsVisible && (
              <div className={classes.actionButtons}>
                <>
                  {editButton}
                  <RoundButtonWithIcon
                    onClick={() => handleDeleteEngagementUser(hire.id)}
                    icon={deleteIcon}
                    iconAlt="Delete"
                    iconSize={16}
                    classnames={[classes.deleteButton]}
                  />
                </>
              </div>
            )}
          </div>
        </div>

        <RoundButtonWithIcon
          onClick={handleExpand}
          icon={arrowIcon}
          iconAlt="Expand"
          classnames={[classes.expandButton, classes.open]}
        />
      </div>

      {isActionButtonsVisible && (
        <div className={classes.actionButtons}>
          {editButton}
          <RoundButtonWithIcon
            onClick={() => handleDeleteEngagementUser(hire.id)}
            icon={deleteIcon}
            iconAlt="Delete"
            iconSize={16}
            classnames={[classes.deleteButton]}
          />
        </div>
      )}
    </div>
  );
};

export default HiresItem;
