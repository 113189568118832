import React from 'react';

import cn from 'classnames';

import Textarea from '../../../../../UI/Textarea';
import classes from './styles.module.scss';

const Responsibilities = ({
  responsibilities,
  isEditable,
  newResponsibilities,
  handleChange,
  isBottom,
}) => {
  let content;

  if (isEditable) {
    content = (
      <Textarea
        value={newResponsibilities}
        onChange={handleChange}
        containerClassnames={[classes.textareaContainer]}
        textareaClassnames={[classes.textarea]}
      />
    );
  } else {
    content = (
      <>
        <span className={classes.title}>Responsibilities:</span>
        <span className={classes.content}>{responsibilities}</span>
      </>
    );
  }

  return (
    <div
      className={cn(classes.Responsibilities, { [classes.bottom]: isBottom })}
    >
      {content}
    </div>
  );
};

export default Responsibilities;
