// import axios from 'axios';
import { axiosPrivate } from '../api/axios';

const UserService = {
  async getMe() {
    const response = await axiosPrivate.get('/users/me');

    return response.data;
  },
  async getUsers({ page, limit, search, roles }) {
    const response = await axiosPrivate.get('/users', {
      params: {
        page,
        limit,
        search,
        roles: JSON.stringify(roles),
      },
    });

    return response.data;
  },
  async getUserById(id) {
    const response = await axiosPrivate.get(`/users/${id}`);

    return response.data;
  },
  async createUser({ name, email, companyName, rate, roles, avatar, details }) {
    const form = new FormData();

    form.append('file', avatar);
    form.append('name', name);
    form.append('email', email);
    form.append('companyName', companyName);
    form.append('rate', rate);
    form.append('roles', roles);
    form.append('details', details);

    const response = await axiosPrivate.post('/users', form);

    return response.data;
  },
  async updateUser({
    id,
    name,
    email,
    companyName,
    rate,
    roles,
    avatar,
    details,
    password,
  }) {
    const form = new FormData();

    const fields = {
      name,
      email,
      rate,
      roles,
      details,
      password,
    };

    Object.keys(fields).forEach((field) => {
      const value = fields[field];

      if (value) {
        form.append(field, value);
      }
    });

    if (avatar) {
      form.append('file', avatar);
    }

    form.append('companyName', companyName);

    const response = await axiosPrivate.patch(`/users/${id}`, form);

    return response.data;
  },
  async deleteUser(id) {
    const response = await axiosPrivate.delete(`/users/${id}`);

    return response.data;
  },
};

export default UserService;
