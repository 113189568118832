import React from 'react';

import prevIcon from '../../../assets/icons/arrow-prev.svg';
import nextIcon from '../../../assets/icons/arrow-next.svg';

import { PAGINATION_COMMANDS } from '../../../constants/main';
import RoundButtonWithIcon from '../../UI/Buttons/RoundButtonWithIcon';
import classes from './styles.module.scss';

const PrevNextPagination = ({ onPagination }) => {
  return (
    <div className={classes.PrevNextPagination}>
      <RoundButtonWithIcon
        icon={prevIcon}
        iconAlt="Previous"
        onClick={() => onPagination(PAGINATION_COMMANDS.PREV)}
      />

      <RoundButtonWithIcon
        icon={nextIcon}
        iconAlt="Next"
        onClick={() => onPagination(PAGINATION_COMMANDS.NEXT)}
      />
    </div>
  );
};

export default PrevNextPagination;
