import React from 'react';

import BillingHistoryItem from './BillingHistoryItem';
import classes from './styles.module.scss';

const BillingHistoryList = ({ items }) => {
  return (
    <ul className={classes.BillingHistoryList}>
      {items?.map((item) => (
        <BillingHistoryItem data={item} key={item.id} />
      ))}
    </ul>
  );
};

export default BillingHistoryList;
