import React, { useCallback, useMemo } from 'react';

import closeIcon from '../../assets/icons/close.svg';

import ModalContainer from '../ModalContainer';
import AddCustomerDropdown from '../../components/Engagements/AddCustomerDropdown';
import classes from './styles.module.scss';

const ChangeUserModal = ({
  show,
  handleClose,
  label,
  users,
  selectedUser,
  onSubmit,
  searchValue,
  onSearch,
  clearSearchValue,
}) => {
  const handleSubmit = useCallback(
    (userId) => {
      onSubmit(userId);
      handleClose();
    },
    [onSubmit, handleClose]
  );

  const filteredUsers = useMemo(() =>
    users?.filter((user) => user.id !== selectedUser.id)
  );

  return (
    <ModalContainer
      show={show}
      handleClose={handleClose}
      contentClassName={classes.ChangeUserModal}
      dialogClassName={classes.dialog}
    >
      <button
        onClick={handleClose}
        className={classes.closeButton}
        type="button"
      >
        <img src={closeIcon} alt="Close" />
      </button>
      <div className={classes.content}>
        <h2 className={classes.title}>{label}</h2>
        <AddCustomerDropdown
          label={label}
          items={filteredUsers}
          selectedItem={selectedUser}
          onItemClick={(user) => handleSubmit(user.id)}
          searchValue={searchValue}
          onSearch={onSearch}
          clearSearchValue={clearSearchValue}
        />
      </div>
    </ModalContainer>
  );
};

export default ChangeUserModal;
