import { USER_ROLES } from './main';

export const roleOptions = [
  { label: 'Customer', value: USER_ROLES.CUSTOMER },
  { label: 'Service Provider', value: USER_ROLES.SERVICE_PROVIDER },
  { label: 'Project Manager', value: USER_ROLES.PROJECT_MANAGER },
];

export const engagementStatusOptions = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Closed',
    value: 'closed',
  },
];
