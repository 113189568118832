import React, { useCallback, useState, useEffect } from 'react';

import { PAGE_NAMES, START_SWITCH_OPTIONS } from '../../constants/main';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import useUser from '../../hooks/useUser';
import classes from './styles.module.scss';
import Switch from '../../components/Toolbar/Switch';
import EditablePageContent from '../../components/EditablePageContent/EditablePageContent';

const GettingStartedPage = () => {
  const [selectedTab, setSelectedTab] = useState(null);
  const [pageNameToFetch, setPageNameToFetch] = useState(
    PAGE_NAMES.CUSTOMER_START
  );

  const { isAdmin, isProjectManager, isServiceProvider, isCustomer } =
    useUser();

  useEffect(() => {
    if (isServiceProvider && !isCustomer) {
      setSelectedTab(START_SWITCH_OPTIONS.SERVICE_PROVIDER);
    } else {
      setSelectedTab(START_SWITCH_OPTIONS.CUSTOMER);
    }
  }, [isCustomer, isServiceProvider]);

  useEffect(() => {
    if (selectedTab === START_SWITCH_OPTIONS.CUSTOMER) {
      setPageNameToFetch(PAGE_NAMES.CUSTOMER_START);
    } else {
      setPageNameToFetch(PAGE_NAMES.SERVICE_PROVIDER_START);
    }
  }, [selectedTab]);

  const handleSwitchChange = useCallback(
    (option) => {
      if (option === selectedTab) {
        return;
      }

      setSelectedTab(option);
    },
    [selectedTab]
  );

  return (
    <div className={classes.gettingStartedPage}>
      <HeaderMenuContainer headerTitle="Getting Started" isUserBadgeVisible>
        <Switch
          customerOption={START_SWITCH_OPTIONS.CUSTOMER}
          providerOption={START_SWITCH_OPTIONS.SERVICE_PROVIDER}
          onChange={handleSwitchChange}
          selectedOption={selectedTab}
          userRoles={{
            isCustomer,
            isServiceProvider,
            isAdmin,
            isProjectManager,
          }}
        />

        <EditablePageContent
          pageName={pageNameToFetch}
          userRoles={{
            isCustomer,
            isServiceProvider,
            isAdmin,
            isProjectManager,
          }}
        />
      </HeaderMenuContainer>
    </div>
  );
};

export default GettingStartedPage;
