import React, { useCallback, useMemo, useEffect, useRef } from 'react';

import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

import logo from '../../assets/images/logo-white.svg';
import inactiveEngagementsIcon from '../../assets/icons/engagements.svg';
import inactiveBillingIcon from '../../assets/icons/billing.svg';
import inactiveSettingsIcon from '../../assets/icons/settings.svg';
import inactiveReferralsIcon from '../../assets/icons/referrals.svg';
import inactiveUsersManagementIcon from '../../assets/icons/letter.svg';
import activeEngagementsIcon from '../../assets/icons/engagements-active.svg';
import activeBillingIcon from '../../assets/icons/billing-active.svg';
import activeSettingsIcon from '../../assets/icons/settings-active.svg';
import activeReferralsIcon from '../../assets/icons/referrals-active.svg';
import activeUsersManagementIcon from '../../assets/icons/letter-active.svg';
import gettingStartedIcon from '../../assets/icons/pen.svg';
import gettingStartedActiveIcon from '../../assets/icons/pen-active.svg';

import useUser from '../../hooks/useUser';
import useCheckDesktopScreen from '../../hooks/useCheckDesktopScreen';
import classes from './styles.module.scss';

const options = [
  {
    id: 1,
    label: '',
    to: '',
    inactiveIcon: null,
    activeIcon: null,
  },
  {
    id: 2,
    label: 'Getting Started',
    to: '/getting-started',
    inactiveIcon: <img src={gettingStartedIcon} alt="Getting started" />,
    activeIcon: <img src={gettingStartedActiveIcon} alt="Getting started" />,
  },
  {
    id: 3,
    label: 'Engagements',
    to: '/engagements',
    inactiveIcon: <img src={inactiveEngagementsIcon} alt="Engagements" />,
    activeIcon: <img src={activeEngagementsIcon} alt="Engagements" />,
  },
  {
    id: 4,
    label: 'Billing & Payments',
    to: '/billing-payments',
    inactiveIcon: <img src={inactiveBillingIcon} alt="Billing & Payments" />,
    activeIcon: <img src={activeBillingIcon} alt="Billing & Payments" />,
  },
  {
    id: 5,
    label: 'Account & Settings',
    to: '/account-settings',
    inactiveIcon: <img src={inactiveSettingsIcon} alt="Account & Settings" />,
    activeIcon: <img src={activeSettingsIcon} alt="Account & Settings" />,
  },
  {
    id: 6,
    label: 'Referrals',
    to: '/referrals',
    inactiveIcon: <img src={inactiveReferralsIcon} alt="Referrals" />,
    activeIcon: <img src={activeReferralsIcon} alt="Referrals" />,
  },
  {
    id: 7,
    label: 'Users Management',
    to: '/users-management',
    // eslint-disable-next-line prettier/prettier
    inactiveIcon: <img src={inactiveUsersManagementIcon} alt="Users Management" />,
    activeIcon: <img src={activeUsersManagementIcon} alt="Users Management" />,
  },
  {
    id: 8,
    label: 'Contact Us',
    to: '/contact-us',
    // eslint-disable-next-line prettier/prettier
    inactiveIcon: <img src={inactiveUsersManagementIcon} alt="Contact Us" />,
    activeIcon: <img src={activeUsersManagementIcon} alt="Contact Us" />,
  },
  {
    id: 9,
    label: 'Service Provider Profile',
    to: '/service-provider-profile',
    // eslint-disable-next-line prettier/prettier
    inactiveIcon: <img src={inactiveSettingsIcon} alt="Service Provider Profile" />,
    activeIcon: <img src={activeSettingsIcon} alt="Service Provider Profile" />,
  },
  {
    id: 10,
    label: '',
    to: '',
    inactiveIcon: null,
    activeIcon: null,
  },
];

const Menu = ({ handleMenuClose, isOpen }) => {
  const menuRef = useRef(null);

  const { pathname } = useLocation();
  const isDesktop = useCheckDesktopScreen();
  const {
    currentUser,
    isAdmin,
    isProjectManager,
    isCustomer,
    isServiceProvider,
  } = useUser();

  useEffect(() => {
    if (!menuRef.current || !isDesktop) {
      return;
    }

    const { right } = menuRef.current.getBoundingClientRect();
    const menuRight = window.innerWidth - right;

    menuRef.current?.style.setProperty('--right', `${menuRight}px`);
  }, [menuRef, window.innerWidth]);

  const handleLinkClick = useCallback(() => {
    if (!isDesktop) {
      handleMenuClose();
    }
  }, []);

  const filteredOptions = useMemo(() => {
    return options.filter((option) => {
      if (isAdmin) {
        return (
          option.label !== 'Contact Us' &&
          option.label !== 'Service Provider Profile'
        );
      }

      if (isProjectManager) {
        return (
          option.label !== 'Contact Us' &&
          option.label !== 'Service Provider Profile'
        );
      }

      if (isServiceProvider) {
        return option.label !== 'Users Management';
      }

      if (isCustomer) {
        return (
          option.label !== 'Users Management' &&
          option.label !== 'Service Provider Profile'
        );
      }

      return false;
    });
  }, [currentUser]);

  const firstLevelPath = `/${pathname.split('/')[1]}`;
  const activeOptionIndex = useMemo(() => {
    return filteredOptions.findIndex((option) => option.to === firstLevelPath);
  }, [filteredOptions, firstLevelPath]);

  return (
    <>
      <div
        ref={menuRef}
        className={cn(classes.Menu, {
          [classes.open]: isOpen,
        })}
      >
        <div className={classes.menuContainer}>
          <Link to="/engagements">
            <img src={logo} alt="LevelDo" className={classes.logo} />
          </Link>
          <ul className={classes.list}>
            {filteredOptions.map((option, index) => {
              const isActive = index === activeOptionIndex;
              const isPrev = activeOptionIndex - 1 === index;
              const isAfter = activeOptionIndex + 1 === index;

              return (
                <li
                  className={cn(classes.item, {
                    [classes.active]: isActive,
                    [classes.prev]: isPrev,
                    [classes.after]: isAfter,
                  })}
                  key={option.id}
                >
                  <Link onClick={handleLinkClick} to={option.to}>
                    <i className={classes.icon}>
                      {isActive ? option.activeIcon : option.inactiveIcon}
                    </i>
                    <span className={classes.label}>{option.label}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {isOpen && <div onClick={handleMenuClose} className={classes.backdrop} />}
    </>
  );
};

export default Menu;
