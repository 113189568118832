import React, { useState, useEffect, useRef } from 'react';

import classes from './styles.module.scss';

const Tooltip = ({ children, text, isTooltipVisible }) => {
  const [left, setLeft] = useState(0);

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (isTooltipVisible && tooltipRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      if (tooltipRect.right > window.innerWidth) {
        const newLeft = window.innerWidth - tooltipRect.width;

        setLeft(newLeft - window.innerWidth);
      }
    }
  }, [isTooltipVisible, tooltipRef]);

  return (
    <div className={classes.Tooltip}>
      {children}
      {isTooltipVisible && (
        <div
          ref={tooltipRef}
          style={{ left }}
          className={classes.visibleTooltip}
        >
          <span className={classes.note}>Note:</span> {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
