import React, { useCallback } from 'react';

import ModalContainer from '../ModalContainer';
import PrimaryButton from '../../components/UI/Buttons/PrimaryButton';
import classes from './styles.module.scss';

const ConfirmActionModal = ({ show, title, handleClose, handleConfirm }) => {
  const handleSubmit = useCallback(() => {
    handleConfirm();
    handleClose();
  }, [handleClose]);

  return (
    <ModalContainer
      show={show}
      handleClose={handleClose}
      contentClassName={classes.ConfirmActionModal}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <h2 className={classes.title}>{title}</h2>

        <div className={classes.buttons}>
          <PrimaryButton onClick={handleClose} variant="outline">
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={handleSubmit}>Confirm</PrimaryButton>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ConfirmActionModal;
