import React, { useState } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';

import LoginContainer from '../../containers/LoginContainer';
import { resetPassword } from '../../helpers/globalAuth';
import Input from '../../components/UI/Input';
import ErrorMessage from '../../components/UI/ErrorMessage';
import PrimaryButton from '../../components/UI/Buttons/PrimaryButton';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  email: yup.string().trim().email('Invalid email address'),
});

const ForgotPasswordPage = () => {
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async ({ email }) => {
    try {
      await resetPassword({ email });
      setIsSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const hasEmailError = formik.touched.email && formik.errors.email;

  let content = (
    <form onSubmit={formik.handleSubmit} className={classes.contentContainer}>
      <h1 className={classes.title}>Recover password</h1>
      <div className={classes.inputs}>
        <div className={classes.inputContainer}>
          <Input
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={hasEmailError}
            name="email"
            placeholder="Email"
          />
          {hasEmailError && <ErrorMessage message={formik.errors.email} />}
        </div>

        <PrimaryButton iconName="arrow" type="submit">
          Continue
        </PrimaryButton>
      </div>
    </form>
  );

  if (isSuccess) {
    content = (
      <div className={classes.contentContainer}>
        <h1 className={classes.title}>Check your email</h1>
        <p className={classes.description}>
          We have sent you an email with instructions to reset your password.
        </p>
      </div>
    );
  }

  return (
    <div className={classes.ForgotPasswordPage}>
      <LoginContainer>{content}</LoginContainer>
    </div>
  );
};

export default ForgotPasswordPage;
