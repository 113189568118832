/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import deleteIcon from '../../../assets/icons/trash-blue.svg';
import editIcon from '../../../assets/icons/pen-active.svg';

import { USER_ROLE_MAPPINGS } from '../../../constants/main';
import useCheckDesktopScreen from '../../../hooks/useCheckDesktopScreen';
import useUser from '../../../hooks/useUser';
import RoundButtonWithIcon from '../../UI/Buttons/RoundButtonWithIcon';
import Avatar from '../../UI/Avatar';
import classes from './styles.module.scss';

const UsersItem = ({ user, handleUserDelete, handleUserEdit }) => {
  const { isAdmin } = useUser();

  const { id, name, roles, email, avatarPath } = user;

  const history = useHistory();
  const isDesktop = useCheckDesktopScreen();

  const handleUserClick = useCallback(() => {
    if (isAdmin && !isDesktop) {
      history.push(`/users-management/${id}/edit`);
    }
  }, []);

  const formattedRoles = roles
    .map((role) => USER_ROLE_MAPPINGS[role])
    .join(' / ');

  return (
    <li onClick={handleUserClick} className={classes.UsersItem}>
      <div className={classes.user}>
        <Avatar imagePath={avatarPath} alt={name} />
        <div className={classes.userInfo}>
          <span className={classes.userName}>{name}</span>
          <span className={classes.userRole}>{formattedRoles}</span>
          <span className={classes.userEmail}>{email}</span>
        </div>
      </div>

      {isAdmin && (
        <div className={classes.actions}>
          <RoundButtonWithIcon
            onClick={() => handleUserEdit(user)}
            icon={editIcon}
            iconAlt="Edit"
            classnames={[classes.icon]}
          />
          <RoundButtonWithIcon
            onClick={() => handleUserDelete(user)}
            icon={deleteIcon}
            iconAlt="Delete"
            classnames={[classes.icon]}
          />
        </div>
      )}
    </li>
  );
};

export default UsersItem;
