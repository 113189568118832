export const API_URL = process.env.REACT_APP_API_URL;
export const STATIC_URL = process.env.REACT_APP_STATIC_URL;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

export const USER_ROLES = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  SERVICE_PROVIDER: 'service_provider',
  PROJECT_MANAGER: 'project_manager',
};

export const USER_ROLE_MAPPINGS = {
  [USER_ROLES.SERVICE_PROVIDER]: 'SP',
  [USER_ROLES.CUSTOMER]: 'Customer',
  [USER_ROLES.PROJECT_MANAGER]: 'PM',
};

export const START_SWITCH_OPTIONS = {
  CUSTOMER: "I'm a customer",
  SERVICE_PROVIDER: "I'm a service provider",
};

export const PAGE_NAMES = {
  CUSTOMER_START: 'customerStart',
  SERVICE_PROVIDER_START: 'serviceProviderStart',
};

export const ENGAGEMENTS_SWITCH_OPTIONS = {
  HIRES: 'hires',
  CUSTOMERS: 'customers',
};

export const PAYMENTS_SWITCH_OPTIONS = {
  PAYMENTS: 'payments',
  GETTING_PAID: 'getting paid',
};

export const PAGINATION_COMMANDS = {
  NEXT: 'next',
  PREV: 'prev',
};
