import React, { createContext, useState, useMemo, useCallback } from 'react';

import DeleteModal from '../modals/DeleteModal';
import EditorModal from '../modals/EditorModal';
import ErrorModal from '../modals/ErrorModal';
import ConfirmActionModal from '../modals/ConfirmActionModal';

export const UiContext = createContext({
  isMenuOpen: false,
  setIsMenuOpen: () => {},
  showDeleteModal: () => {},
  showEditorModal: () => {},
  isEditorModalOpen: false,
});

const UiContextProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [deleteModalType, setDeleteModalType] = useState(null);
  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false);
  const [editorModalProps, setEditorModalProps] = useState({
    html: '',
    setHtml: () => {},
  });
  const [isConfirmActionModalOpen, setIsConfirmActionModalOpen] =
    useState(false);
  const [confirmActionModalTitle, setConfirmActionModalTitle] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const showDeleteModal = useCallback(({ data, handleDelete, type }) => {
    setDataToDelete(data);
    setOnConfirm(() => handleDelete);
    setDeleteModalType(type);
    setIsDeleteModalOpen(true);
  }, []);

  const showEditorModal = useCallback(({ html, setHtml, handleUpdate }) => {
    setEditorModalProps({ html, setHtml });
    setOnConfirm(() => handleUpdate);
    setIsEditorModalOpen(true);
  }, []);

  const showConfirmActionModal = useCallback(({ title, handleSubmit }) => {
    setConfirmActionModalTitle(title);
    setOnConfirm(() => handleSubmit);
    setIsConfirmActionModalOpen(true);
  }, []);

  const showErrorModal = useCallback(({ message }) => {
    setErrorModalMessage(message);
    setIsErrorModalOpen(true);

    setTimeout(() => {
      setIsErrorModalOpen(false);
    }, 3000);
  }, []);

  const value = useMemo(
    () => ({
      isMenuOpen,
      setIsMenuOpen,
      showDeleteModal,
      showEditorModal,
      isEditorModalOpen,
      showConfirmActionModal,
      showErrorModal,
    }),
    [
      isMenuOpen,
      setIsMenuOpen,
      showDeleteModal,
      showEditorModal,
      isEditorModalOpen,
      showConfirmActionModal,
      showErrorModal,
    ]
  );

  return (
    <UiContext.Provider value={value}>
      {children}
      <DeleteModal
        show={isDeleteModalOpen}
        data={dataToDelete}
        handleConfirm={onConfirm}
        type={deleteModalType}
        handleClose={() => setIsDeleteModalOpen(false)}
      />
      <EditorModal
        show={isEditorModalOpen}
        handleClose={() => setIsEditorModalOpen(false)}
        handleConfirm={onConfirm}
        html={editorModalProps.html}
        setHtml={editorModalProps.setHtml}
      />
      <ConfirmActionModal
        show={isConfirmActionModalOpen}
        title={confirmActionModalTitle}
        handleClose={() => setIsConfirmActionModalOpen(false)}
        handleConfirm={onConfirm}
      />
      <ErrorModal
        show={isErrorModalOpen}
        message={errorModalMessage}
        handleClose={() => setIsErrorModalOpen(false)}
      />
    </UiContext.Provider>
  );
};

export default UiContextProvider;
