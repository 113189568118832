import React from 'react';

import cn from 'classnames';

import classes from './styles.module.scss';

const RoundButtonWithIcon = ({
  onClick,
  classnames = [],
  icon,
  iconAlt,
  reference,
  iconSize,
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      ref={reference}
      className={cn(classes.RoundButtonWithIcon, ...classnames)}
      type="button"
      {...rest}
    >
      <img
        src={icon}
        alt={iconAlt}
        style={{ width: iconSize, height: iconSize }}
      />
    </button>
  );
};

export default RoundButtonWithIcon;
