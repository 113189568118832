import axios from '../api/axios';

export async function login({ email, password, roles }) {
  try {
    const response = await axios.post(`/login`, {
      email,
      password,
      roles: JSON.stringify(roles),
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
}

export async function resetPassword({ email }) {
  try {
    await axios.post(`/forgot-password`, {
      email,
    });
  } catch (error) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
}

export async function createPassword({ password, token }) {
  try {
    await axios.post(`/create-password`, {
      password,
      token,
    });
  } catch (error) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
}

export async function logout(callback) {
  try {
    await axios.post('/logout');
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
  if (callback) {
    callback();
  }
}
