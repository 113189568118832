import { useQuery } from 'react-query';

import UserService from '../services/UserService';
import { USER_ROLES } from '../constants/main';

const useUser = () => {
  const { data: currentUser } = useQuery('currentUser', UserService.getMe, {
    staleTime: Infinity,
  });

  const isAdmin = currentUser.roles?.includes(USER_ROLES.ADMIN);
  const isProjectManager = currentUser.roles?.includes(
    USER_ROLES.PROJECT_MANAGER
  );
  const isCustomer = currentUser.roles?.includes(USER_ROLES.CUSTOMER);
  const isServiceProvider = currentUser.roles?.includes(
    USER_ROLES.SERVICE_PROVIDER
  );

  return {
    currentUser,
    isAdmin,
    isProjectManager,
    isCustomer,
    isServiceProvider,
  };
};

export default useUser;
