import React, { useContext, useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import cn from 'classnames';

import eyeIcon from '../../assets/icons/eye.svg';

import { USER_ROLES } from '../../constants/main';
import { login } from '../../helpers/globalAuth';
import LoginContainer from '../../containers/LoginContainer';
import Input from '../../components/UI/Input';
import ErrorMessage from '../../components/UI/ErrorMessage';
import PrimaryButton from '../../components/UI/Buttons/PrimaryButton';
import classes from './styles.module.scss';
import { AuthContext } from '../../context/AuthContext';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address.')
    .required('Email is required.'),
  password: yup.string().trim().required('Password is required.'),
});

const LoginPage = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isGlobalErrorVisible, setIsGlobalErrorVisible] = useState(false);
  const { setAuth, persist, setPersist } = useContext(AuthContext);

  const history = useHistory();

  const handleSubmit = async ({ email, password }) => {
    try {
      const data = await login({
        email,
        password,
        roles: [
          USER_ROLES.CUSTOMER,
          USER_ROLES.PROJECT_MANAGER,
          USER_ROLES.SERVICE_PROVIDER,
        ],
      });
      const accessToken = data?.accessToken;
      setAuth({ accessToken });
      history.push('/engagements');
    } catch (error) {
      setIsGlobalErrorVisible(true);
    }
  };

  const handlePasswordVisibilityToggle = () =>
    setIsPasswordVisible((prevState) => !prevState);

  const handlePersistTogle = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem('persist', persist);
  }, [persist]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const hasEmailError = formik.touched.email && formik.errors.email;
  const hasPasswordError = formik.touched.password && formik.errors.password;

  const passwordType = isPasswordVisible ? 'text' : 'password';

  return (
    <div className={classes.LoginPage}>
      <LoginContainer>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <h1 className={classes.title}>Welcome back!</h1>
          <div className={classes.inputs}>
            <div className={classes.inputContainer}>
              <Input
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasError={hasEmailError || isGlobalErrorVisible}
                type="text"
                name="email"
                placeholder="Email"
                autoComplete="email"
              />
              {hasEmailError && <ErrorMessage message={formik.errors.email} />}
            </div>

            <div className={classes.inputContainer}>
              <div className={classes.passwordContainer}>
                <Input
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasError={hasPasswordError || isGlobalErrorVisible}
                  type={passwordType}
                  name="password"
                  placeholder="Password"
                  autoComplete="current-password"
                />
                {formik.values.password && (
                  <button
                    onClick={handlePasswordVisibilityToggle}
                    type="button"
                    className={cn(classes.passwordVisibilityToggle, {
                      [classes.slashed]: !isPasswordVisible,
                    })}
                  >
                    <img src={eyeIcon} alt="Eye" className={classes.eyeIcon} />
                  </button>
                )}
              </div>
              {hasPasswordError && (
                <ErrorMessage message={formik.errors.password} />
              )}

              {isGlobalErrorVisible && (
                <ErrorMessage message="Invalid email or password." />
              )}
            </div>

            <label htmlFor="persist" className={classes.checkboxContainer}>
              <Input
                checked={persist}
                onChange={handlePersistTogle}
                type="checkbox"
                id="persist"
              />
              Remember me
              {/* I honestly don't know why, but without this comment eslint shows error */}
            </label>
            <Link className={classes.forgotPassword} to="/forgot-password">
              Forgot password
            </Link>

            <PrimaryButton type="submit">Login</PrimaryButton>
          </div>
        </form>
      </LoginContainer>
    </div>
  );
};

export default LoginPage;
