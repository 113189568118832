// import axios from 'axios';
import { axiosPrivate } from '../api/axios';

const PaymentService = {
  async createCustomer(stripeToken) {
    const response = await axiosPrivate.post('/payments/customer', {
      stripeToken,
    });

    return response.data;
  },
  async getCardDetails() {
    const response = await axiosPrivate.get(`/payments/card`);

    return response.data;
  },
  async getBalance() {
    const response = await axiosPrivate.get(`/payments/balance`);

    return response.data;
  },
  async getPaymentLogs({ page, limit, type }) {
    const response = await axiosPrivate.get(`/payments/logs`, {
      params: {
        page,
        limit,
        type,
      },
    });

    return response.data;
  },
};

export default PaymentService;
