import React from 'react';

import cn from 'classnames';

import classes from './styles.module.scss';

const Input = ({ hasError, classnames = [], reference, ...rest }) => {
  return (
    <input
      className={cn(
        classes.Input,
        {
          [classes.error]: hasError,
        },
        ...classnames
      )}
      ref={reference}
      {...rest}
    />
  );
};

export default Input;
