import React, { useState, useCallback, useContext } from 'react';

import axios from 'axios';
import { useQuery } from 'react-query';

import { PAYMENTS_SWITCH_OPTIONS } from '../../constants/main';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import PaymentService from '../../services/PaymentService';
import useUser from '../../hooks/useUser';
import Card from '../../components/Payments/Card';
import Switch from '../../components/Toolbar/Switch';
import BillingHistoryList from '../../components/Payments/BillingHistoryList';
import Pagination from '../../components/Pagination';
import classes from './styles.module.scss';
import { UiContext } from '../../context/UiContext';

const PaymentsPage = () => {
  const { isServiceProvider, isCustomer } = useUser();
  const { showErrorModal } = useContext(UiContext);

  const [selectedBillingHistoryType, setSelectedBillingHistoryType] = useState(
    isCustomer
      ? PAYMENTS_SWITCH_OPTIONS.PAYMENTS
      : PAYMENTS_SWITCH_OPTIONS.GETTING_PAID
  );
  const [currentPage, setCurrentPage] = useState(1);

  const handleSwitchChange = useCallback((type) => {
    setSelectedBillingHistoryType(type);
  }, []);

  const {
    data: cardDetails,
    refetch,
    isFetched: isCardFetched,
  } = useQuery('card', PaymentService.getCardDetails, {
    retry: false,
  });

  const { data: balance } = useQuery('balance', PaymentService.getBalance, {
    enabled: !!cardDetails,
  });

  const { data: billingHistory } = useQuery(
    ['paymentLogs', currentPage, selectedBillingHistoryType],
    () =>
      PaymentService.getPaymentLogs({
        page: currentPage,
        limit: 7,
        type: selectedBillingHistoryType,
      }),
    {
      keepPreviousData: true,
    }
  );

  const handleSubmit = async (stripeToken) => {
    try {
      const { data } = await axios.post('/payments/customer', {
        stripeToken,
      });
      refetch();

      if (isServiceProvider) {
        window.location.assign(data.url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (stripeToken) => {
    try {
      await axios.patch('/payments/customer', {
        stripeToken,
      });
      refetch();
    } catch (error) {
      showErrorModal({ message: error.message });
      console.log(error);
    }
  };

  const handlePageChange = useCallback((event) => {
    const { selected } = event;

    window.scrollTo(0, 0);
    setCurrentPage(selected + 1);
  }, []);

  return (
    <div className={classes.PaymentsPage}>
      <HeaderMenuContainer headerTitle="Billing & Payments" isUserBadgeVisible>
        <h1 className={classes.title}>Billing & Payments</h1>
        <div className={classes.innerContainer}>
          <div className={classes.billingHistoryContainer}>
            <Switch
              customerOption={PAYMENTS_SWITCH_OPTIONS.PAYMENTS}
              providerOption={PAYMENTS_SWITCH_OPTIONS.GETTING_PAID}
              selectedOption={selectedBillingHistoryType}
              onChange={handleSwitchChange}
              userRoles={{ isCustomer, isServiceProvider }}
            />
            {billingHistory?.paymentLogs?.length > 0 ? (
              <BillingHistoryList items={billingHistory?.paymentLogs} />
            ) : (
              <p className={classes.noBillingHistory}>
                You have no billing history yet.{' '}
                {isCardFetched &&
                  !cardDetails &&
                  'Please add the card to make payments.'}
              </p>
            )}
          </div>

          <Card
            cardDetails={cardDetails}
            balanceAmount={balance?.available[0].amount / 100}
            onSubmit={handleSubmit}
            onUpdate={handleUpdate}
            className={classes.cardContainer}
          />
        </div>
        <Pagination
          pageCount={billingHistory?.pageCount}
          handlePageChange={handlePageChange}
        />
      </HeaderMenuContainer>
    </div>
  );
};

export default PaymentsPage;
