import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { createPassword } from '../../helpers/globalAuth';
import LoginContainer from '../../containers/LoginContainer';
import Input from '../../components/UI/Input';
import ErrorMessage from '../../components/UI/ErrorMessage';
import PrimaryButton from '../../components/UI/Buttons/PrimaryButton';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  password: yup
    .string()
    .trim()
    .min(8, 'Password must be at least 8 characters long.')
    .required('Password is required.'),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('password'), null], 'Passwords must match.')
    .required('Confirm password is required.'),
});

const CreatePasswordPage = () => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const handleSubmit = async ({ password }) => {
    try {
      await createPassword({
        token,
        password,
      });
      history.push('/engagements');
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const hasPasswordError = formik.touched.password && formik.errors.password;
  const hasConfirmPasswordError =
    formik.touched.confirmPassword && formik.errors.confirmPassword;

  return (
    <div className={classes.CreatePasswordPage}>
      <LoginContainer>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <h1 className={classes.title}>To continue create a password</h1>
          <div className={classes.inputs}>
            <div className={classes.inputContainer}>
              <Input
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasError={hasPasswordError}
                type="password"
                name="password"
                placeholder="Password"
              />
              {hasPasswordError && (
                <ErrorMessage message={formik.errors.password} />
              )}
            </div>

            <div className={classes.inputContainer}>
              <Input
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasError={hasConfirmPasswordError}
                type="password"
                name="confirmPassword"
                placeholder="Confirm password"
              />
              {hasConfirmPasswordError && (
                <ErrorMessage message={formik.errors.confirmPassword} />
              )}
            </div>

            <PrimaryButton iconName="arrow" type="submit">
              Continue
            </PrimaryButton>
          </div>
        </form>
      </LoginContainer>
    </div>
  );
};

export default CreatePasswordPage;
