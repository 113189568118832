import React, { useCallback, useContext } from 'react';

import cn from 'classnames';

import { UiContext } from '../../context/UiContext';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import classes from './styles.module.scss';

const HeaderMenuContainer = ({
  headerTitle,
  isUserBadgeVisible,
  contentClassname,
  children,
}) => {
  const { isMenuOpen, setIsMenuOpen } = useContext(UiContext);

  const handleMenuToggle = useCallback(() => {
    setIsMenuOpen((prevState) => !prevState);
  }, []);

  const handleMenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <div className={classes.HeaderMenuContainer}>
      <Menu
        handleMenuToggle={handleMenuToggle}
        handleMenuClose={handleMenuClose}
        isOpen={isMenuOpen}
      />
      <div className={classes.container}>
        <Header
          handleMenuToggle={handleMenuToggle}
          title={headerTitle}
          isUserBadgeVisible={isUserBadgeVisible}
        />
        <main className={cn(classes.content, contentClassname)}>
          {children}
        </main>
      </div>
    </div>
  );
};

export default HeaderMenuContainer;
