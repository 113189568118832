import React from 'react';

import EngagementsItem from '../EngagementsItem';
import classes from './styles.module.scss';

const EngagementsList = ({
  engagements,
  serviceProviders,
  handleUpdate,
  handleServiceProviderSearchValueChange,
  serviceProviderSearchValue,
  clearServiceProviderSearchValue,
  createEngagementUserMutation,
  selectedTab,
  addHoursToEngagementUserMutation,
}) => {
  return (
    <ul className={classes.EngagementsList}>
      {engagements.map((engagement) => (
        <EngagementsItem
          key={engagement.id}
          engagement={engagement}
          serviceProviders={serviceProviders}
          handleServiceProviderSearchValueChange={
            handleServiceProviderSearchValueChange
          }
          serviceProviderSearchValue={serviceProviderSearchValue}
          clearServiceProviderSearchValue={clearServiceProviderSearchValue}
          handleUpdate={handleUpdate}
          createEngagementUserMutation={createEngagementUserMutation}
          selectedTab={selectedTab}
          addHoursToEngagementUserMutation={addHoursToEngagementUserMutation}
        />
      ))}
    </ul>
  );
};

export default EngagementsList;
