import React from 'react';

import cn from 'classnames';

import classes from './styles.module.scss';

const Textarea = ({
  hasError,
  containerClassnames = [],
  textareaClassnames = [],
  ...rest
}) => {
  return (
    <div
      className={cn(
        classes.Textarea,
        {
          [classes.error]: hasError,
        },
        ...containerClassnames
      )}
    >
      <textarea className={cn(...textareaClassnames)} {...rest} />
    </div>
  );
};

export default Textarea;
