import React from 'react';

import editIcon from '../../../assets/icons/pen.svg';

import Input from '../../UI/Input';
import ErrorMessage from '../../UI/ErrorMessage';
import RoundButtonWithIcon from '../../UI/Buttons/RoundButtonWithIcon';
import classes from './styles.module.scss';

const ServiceProviderDetails = ({
  rate,
  onRateChange,
  onRateBlur,
  isRateTouched,
  rateErrors,
  onEditorChange,
  onPublicProfileClick,
  isCustomLinkDisabled,
}) => {
  return (
    <div className={classes.ServiceProviderDetails}>
      <div className={classes.inputContainer}>
        <div className={classes.rateContainer}>
          <Input
            value={rate}
            name="rate"
            type="number"
            onChange={onRateChange}
            onBlur={onRateBlur}
            classnames={[classes.input, classes.rate]}
            placeholder="Service Provider Standard Billable Rate"
            hasError={isRateTouched && rateErrors}
          />
        </div>
        {isRateTouched && rateErrors && <ErrorMessage message={rateErrors} />}
      </div>
      <div className={classes.buttonsContainer}>
        <button
          onClick={onPublicProfileClick}
          type="button"
          className={classes.publicProfileButton}
          disabled={isCustomLinkDisabled}
        >
          Custom link
        </button>

        <RoundButtonWithIcon
          icon={editIcon}
          iconAlt="Edit"
          onClick={onEditorChange}
          classnames={[classes.editorButton]}
        />
      </div>
    </div>
  );
};

export default ServiceProviderDetails;
