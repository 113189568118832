/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import cn from 'classnames';

import classes from './styles.module.scss';

export default function Checkbox({
  isChecked,
  value,
  onClick,
  label,
  labelClass,
  isDisabled = false,
  color,
}) {
  return (
    <div
      className={cn(classes.Checkbox, {
        [classes.white]: color === 'white',
      })}
    >
      <label
        className={cn(classes.container, {
          [classes.disabled]: isDisabled,
        })}
      >
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onClick}
          disabled={isDisabled}
          value={value}
        />
        <span
          className={cn(classes.checkmark, {
            [classes.disabled]: isDisabled,
          })}
        />
        <span className={cn(classes.label, labelClass)}>{label}</span>
      </label>
    </div>
  );
}
