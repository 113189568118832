import React, { useCallback, useState } from 'react';

import axios from 'axios';
import { useHistory } from 'react-router-dom';

import cn from 'classnames';
import personImage from '../../assets/images/random-person.png';
import letterIcon from '../../assets/icons/letter.svg';
import homeIcon from '../../assets/icons/home.svg';
import backIcon from '../../assets/icons/arrow-back-white.svg';

import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import Input from '../../components/UI/Input';
import Textarea from '../../components/UI/Textarea';
import PrimaryButton from '../../components/UI/Buttons/PrimaryButton';
import classes from './styles.module.scss';

const contact = async ({ subject, message }) => {
  const response = await axios.post('/contact', {
    subject,
    message,
  });

  return response.data;
};

const ContactUsPage = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const history = useHistory();

  const handleSubjectChange = useCallback((event) => {
    setSubject(event.target.value);
  }, []);

  const handleMessageChange = useCallback((event) => {
    setMessage(event.target.value);
  }, []);

  const handleSendButtonClick = useCallback(async () => {
    try {
      await contact({ subject, message });
      setIsSubmitted(true);
    } catch (error) {
      console.log(error);
    }
  }, [subject, message]);

  const handleBackButtonClick = useCallback(() => {
    history.push('/engagements');
  }, [history]);

  let content = (
    <>
      <p className={classes.textWrapper}>
        <span>If you have any questions, please feel free to</span>
        <span className={classes.hint}>contact us.</span>
      </p>
      <form className={classes.form}>
        <Input
          value={subject}
          onChange={handleSubjectChange}
          placeholder="Subject"
          classnames={[classes.input]}
        />
        <Textarea
          value={message}
          onChange={handleMessageChange}
          placeholder="Your question / problem"
          containerClassnames={[classes.textareaContainer]}
          textareaClassnames={[classes.textarea]}
        />
      </form>
    </>
  );

  if (isSubmitted) {
    content = (
      <div className={classes.submit}>
        <h3 className={classes.title}>
          <img src={homeIcon} alt="" className={classes.homeIcon} /> Your
          request has been sent.
        </h3>

        <img src={homeIcon} alt="" className={classes.homeIcon} />

        <p className={classes.text}>
          Thank you for reaching out to us. We will get back tou you as soon as
          possible.
        </p>
        <PrimaryButton
          onClick={handleBackButtonClick}
          classnames={[classes.backButton]}
        >
          <img src={backIcon} alt="Back" />
          Back to Engagements
        </PrimaryButton>
      </div>
    );
  }

  const isSendButtonDisabled = !subject || !message;

  return (
    <div className={classes.ContactUsPage}>
      <HeaderMenuContainer headerTitle="Contact us" isUserBadgeVisible>
        <div className={classes.content}>
          <div className={classes.left}>
            <h1 className={classes.title}>Contact us</h1>
            <div
              className={cn(classes.formContainer, {
                [classes.submitted]: isSubmitted,
              })}
            >
              <img src={personImage} alt="" className={classes.personImage} />
              {content}
            </div>

            {!isSubmitted && (
              <PrimaryButton
                onClick={handleSendButtonClick}
                disabled={isSendButtonDisabled}
                classnames={[classes.sendButton]}
              >
                Send <img src={letterIcon} alt="" />
              </PrimaryButton>
            )}
          </div>

          <div className={classes.right}>
            <img src={personImage} alt="" />
          </div>
        </div>
      </HeaderMenuContainer>
    </div>
  );
};

export default ContactUsPage;
