import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ReactQuill from 'react-quill';
import PageService from '../../services/PageService';
import pen from '../../assets/icons/pen-active.svg';
import close from '../../assets/icons/close.svg';
import 'react-quill/dist/quill.snow.css';

import classes from './styles.module.scss';
import RoundButtonWithIcon from '../UI/Buttons/RoundButtonWithIcon';
import PrimaryButton from '../UI/Buttons/PrimaryButton';

export default function EditablePageContent({ pageName, userRoles }) {
  const [content, setContent] = useState('');
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const { isAdmin, isProjectManager } = userRoles;

  const {
    data: page,
    isLoading,
    refetch,
  } = useQuery({
    queryFn: () => PageService.getPageByName({ name: pageName }),
    queryKey: ['page'],
  });

  useEffect(() => {
    if (page) setContent(page.content);
  }, [page]);

  useEffect(() => {
    refetch();
  }, [pageName]);

  const handleEditToggle = () => {
    if (isBeingEdited) setContent(page.content);
    setIsBeingEdited(!isBeingEdited);
  };

  const handleSave = async () => {
    await PageService.updatePage({ name: pageName, newContent: content });
    setIsBeingEdited(false);
    refetch();
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  };

  return (
    <div className={classes.contentContainer}>
      {(isAdmin || isProjectManager) && (
        <RoundButtonWithIcon
          icon={isBeingEdited ? close : pen}
          classnames={[classes.editButton]}
          onClick={handleEditToggle}
        />
      )}

      {!isLoading && !isBeingEdited && (
        <div dangerouslySetInnerHTML={{ __html: page.content }} />
      )}

      {isBeingEdited && (
        <>
          <ReactQuill
            value={content}
            onChange={setContent}
            theme="snow"
            modules={modules}
          />
          <PrimaryButton
            onClick={handleSave}
            iconName="checkmark"
            classnames={[classes.saveButton]}
          >
            Save
          </PrimaryButton>
        </>
      )}
    </div>
  );
}
