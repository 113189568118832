import React, { useCallback, useState, useEffect, useContext } from 'react';

import { useMutation, useQuery } from 'react-query';

import { ENGAGEMENTS_SWITCH_OPTIONS, USER_ROLES } from '../../constants/main';
import { engagementStatusOptions } from '../../constants/options';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import UserService from '../../services/UserService';
import EngagementService from '../../services/EngagementService';
import useCheckDesktopScreen from '../../hooks/useCheckDesktopScreen';
import useUser from '../../hooks/useUser';
import useSearchState from '../../hooks/useSearchState';
import Toolbar from '../../components/Toolbar';
import Pagination from '../../components/Pagination';
import EngagementsList from '../../components/Engagements/EngagementsList';
import classes from './styles.module.scss';
import { UiContext } from '../../context/UiContext';

const EngagementsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);
  const { showErrorModal } = useContext(UiContext);

  const [
    engagementsSearch,
    debouncedEngagementsSearch,
    handleEngagementsSearchChange,
    clearEngagementsSearch,
  ] = useSearchState();
  const [
    serviceProvidersSearch,
    debouncedServiceProvidersSearch,
    handleServiceProvidersSearchChange,
    clearServiceProvidersSearch,
  ] = useSearchState();

  const isDesktop = useCheckDesktopScreen();
  const { isAdmin, isProjectManager, isServiceProvider, isCustomer } =
    useUser();

  const pageSize = 11;

  const {
    data,
    refetch: refetchEngagements,
    isFetched,
  } = useQuery(
    [
      'engagements',
      currentPage,
      debouncedEngagementsSearch,
      selectedTab,
      filterOptions,
    ],
    () =>
      EngagementService.getEngagements({
        page: currentPage,
        limit: pageSize,
        search: debouncedEngagementsSearch,
        type: selectedTab,
        statuses: filterOptions,
      }),
    {
      keepPreviousData: true,
    }
  );

  const { data: serviceProviders } = useQuery(
    ['serviceProviders', debouncedServiceProvidersSearch],
    () =>
      UserService.getUsers({
        roles: [USER_ROLES.SERVICE_PROVIDER],
        search: debouncedServiceProvidersSearch,
      }),
    {
      keepPreviousData: true,
      enabled: isAdmin || isProjectManager,
    }
  );

  const { mutate: updateEngagementMutation } = useMutation(
    EngagementService.updateEngagement,
    {
      onSuccess: () => {
        refetchEngagements();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { mutate: createEngagementUserMutation } = useMutation(
    EngagementService.createEngagementUser,
    {
      onSuccess: () => {
        refetchEngagements();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { mutate: addHoursToEngagementUserMutation } = useMutation(
    EngagementService.addHoursToEngagementUser,
    {
      onSuccess: () => {
        refetchEngagements();
      },
      onError: (error) => {
        console.log(error);
        showErrorModal({
          message: `You are trying to bill more than maximum billiable hours. Contact your admin for any questions.`,
        });
      },
    }
  );

  useEffect(() => {
    if (isCustomer) {
      setSelectedTab(ENGAGEMENTS_SWITCH_OPTIONS.HIRES);
    } else if (isServiceProvider) {
      setSelectedTab(ENGAGEMENTS_SWITCH_OPTIONS.CUSTOMERS);
    }
  }, [isCustomer, isServiceProvider]);

  useEffect(() => {
    if (debouncedEngagementsSearch || filterOptions.length) {
      setCurrentPage(1);
    }
  }, [debouncedEngagementsSearch, filterOptions]);

  const handlePageChange = useCallback((event) => {
    const { selected } = event;

    window.scrollTo(0, 0);
    setCurrentPage(selected + 1);
  }, []);

  const handleSwitchChange = useCallback(
    (option) => {
      if (option === selectedTab) {
        return;
      }

      setSelectedTab(option);
      clearEngagementsSearch();
      setCurrentPage(1);
    },
    [selectedTab, clearEngagementsSearch]
  );

  const handleFilterOptionsChange = useCallback((event) => {
    const { checked, value } = event.target;

    if (checked) {
      setFilterOptions((prevState) => [...prevState, value]);
    } else {
      setFilterOptions((prevState) =>
        prevState.filter((option) => option !== value)
      );
    }
  }, []);

  return (
    <div className={classes.EngagementsPage}>
      <HeaderMenuContainer headerTitle="Engagements" isUserBadgeVisible>
        <Toolbar
          title="Engagements"
          createButtonLink="/engagements/create"
          createButtonLabel="New engagement"
          searchValue={engagementsSearch}
          handleSearchValueChange={handleEngagementsSearchChange}
          hasCreateButton={isAdmin || isProjectManager}
          hasSwitch={isDesktop}
          hasSearch
          hasFilter
          filterOptions={engagementStatusOptions}
          handleFilterOptionsChange={handleFilterOptionsChange}
          selectedSwitchOption={selectedTab}
          onSwitchChange={handleSwitchChange}
          userRoles={{ isCustomer, isServiceProvider }}
        />

        <div className={classes.engagementsListContainer}>
          {isFetched && (
            <EngagementsList
              engagements={data.engagements}
              serviceProviders={serviceProviders}
              handleUpdate={updateEngagementMutation}
              handleServiceProviderSearchValueChange={
                handleServiceProvidersSearchChange
              }
              serviceProviderSearchValue={serviceProvidersSearch}
              clearServiceProviderSearchValue={clearServiceProvidersSearch}
              createEngagementUserMutation={createEngagementUserMutation}
              selectedTab={selectedTab}
              addHoursToEngagementUserMutation={
                addHoursToEngagementUserMutation
              }
            />
          )}
        </div>

        <Pagination
          pageCount={data?.pageCount}
          handlePageChange={handlePageChange}
        />
      </HeaderMenuContainer>
    </div>
  );
};

export default EngagementsPage;
