import React, { useCallback, useRef, useState } from 'react';

import cn from 'classnames';

import checkmarkIcon from '../../../../assets/icons/checkmark.svg';

import useCheckDesktopScreen from '../../../../hooks/useCheckDesktopScreen';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import PrimaryButton from '../../../UI/Buttons/PrimaryButton';
import Input from '../../../UI/Input';
import RoundButtonWithIcon from '../../../UI/Buttons/RoundButtonWithIcon';
import classes from './styles.module.scss';

const LogHours = ({ className, onAddHours, serviceProviderStatus }) => {
  const [isLogHoursOpen, setIsLogHoursOpen] = useState(false);
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');

  const inputsRef = useRef(null);
  const hoursRef = useRef(null);
  const minutesRef = useRef(null);
  const submitRef = useRef(null);

  useOnClickOutside(
    inputsRef,
    () => {
      setIsLogHoursOpen(false);
      setHours('');
      setMinutes('');
    },
    submitRef
  );

  const isDesktop = useCheckDesktopScreen();

  const handleLogHoursOpen = useCallback(() => {
    setIsLogHoursOpen(true);
    setTimeout(() => hoursRef.current.focus());
  }, []);

  const handleHoursChange = useCallback((event) => {
    const { value } = event.target;

    const positiveNumber =
      !!value && Math.abs(value) >= 0 ? Math.abs(value) : '';

    if (positiveNumber.toString().length > 2) {
      minutesRef.current.focus();
      return;
    }

    setHours(positiveNumber);
  }, []);

  const handleMinutesChange = useCallback((event) => {
    const { value } = event.target;

    const validMinutes =
      Math.max(0, Math.min(Math.abs(parseFloat(value) || 0), 60)) || '';

    setMinutes(validMinutes);
  }, []);

  const handleMinutesKeyDown = useCallback(
    (event) => {
      if (event.key === 'Backspace' && !minutes) {
        hoursRef.current.focus();
      }
    },
    [minutes]
  );

  const handleSubmit = useCallback(() => {
    const calculatedHours = hours + minutes / 60;

    onAddHours(calculatedHours);
    setIsLogHoursOpen(false);
    setHours('');
    setMinutes('');
  }, [hours, minutes, onAddHours]);

  return (
    <div className={cn(classes.LogHours, className)}>
      {isLogHoursOpen ? (
        <div ref={inputsRef} className={classes.inputContainer}>
          <Input
            reference={hoursRef}
            classnames={[classes.input]}
            placeholder="hh"
            value={hours}
            onChange={handleHoursChange}
            type="number"
          />
          <Input
            reference={minutesRef}
            classnames={[classes.input]}
            placeholder="mm"
            value={minutes}
            onChange={handleMinutesChange}
            onKeyDown={handleMinutesKeyDown}
            type="number"
          />
          <RoundButtonWithIcon
            onClick={handleSubmit}
            reference={submitRef}
            disabled={!hours && !minutes}
            classnames={[classes.submitButton]}
            icon={checkmarkIcon}
            iconAlt="Submit"
          />
        </div>
      ) : (
        <PrimaryButton
          onClick={handleLogHoursOpen}
          classnames={[classes.logHoursButton]}
          iconName={isDesktop && 'arrow'}
          disabled={serviceProviderStatus !== 'working'}
        >
          Log Hours
        </PrimaryButton>
      )}
    </div>
  );
};

export default LogHours;
