import React, { useState, useCallback, useMemo, useContext } from 'react';

import cn from 'classnames';

import editIcon from '../../../assets/icons/pen.svg';
import deleteIcon from '../../../assets/icons/trash.svg';

import {
  ENGAGEMENTS_SWITCH_OPTIONS,
  USER_ROLES,
} from '../../../constants/main';
import { UiContext } from '../../../context/UiContext';
import useUser from '../../../hooks/useUser';
import useCheckDesktopScreen from '../../../hooks/useCheckDesktopScreen';
import RoundButtonWithIcon from '../../UI/Buttons/RoundButtonWithIcon';
import InfoIcon from '../../UI/InfoIcon';
import Title from './Title';
import Description from './Description';
import Status from './Status';
import Customer from './Customer';
import ProjectManager from './ProjectManager';
import HiresList from './HiresList';
import AddHiresDropdown from './AddHiresDropdown';
import Rate from './HiresList/HiresItem/Rate';
import LogHours from './LogHours';
import TotalHours from './HiresList/HiresItem/TotalHours';
import classes from './styles.module.scss';

const EDITABLE_FIELDS = {
  TITLE: 'title',
  DESCRIPTION: 'description',
};

const ENGAGEMENT_STATUS_VALUES = {
  ACTIVE: 'active',
  PENDING: 'pending',
  CLOSED: 'closed',
};

const ENGAGEMENT_STATUS_COLORS = {
  [ENGAGEMENT_STATUS_VALUES.ACTIVE]: '#4BD2B1',
  [ENGAGEMENT_STATUS_VALUES.PENDING]: 'rgba(135, 69, 218, 0.50)',
  [ENGAGEMENT_STATUS_VALUES.CLOSED]: '#606161',
};

const ENGAGEMENT_USER_STATUS_VALUES = {
  ACTIVE: 'working',
  PENDING: 'on hold',
  CLOSED: 'ended',
};

const ENGAGEMENT_USER_STATUS_COLORS = {
  [ENGAGEMENT_USER_STATUS_VALUES.ACTIVE]: '#4BD2B1',
  [ENGAGEMENT_USER_STATUS_VALUES.PENDING]: 'rgba(135, 69, 218, 0.50)',
  [ENGAGEMENT_USER_STATUS_VALUES.CLOSED]: '#606161',
};

const EngagementsItem = ({
  engagement,
  serviceProviders,
  handleUpdate,
  handleServiceProviderSearchValueChange,
  serviceProviderSearchValue,
  clearServiceProviderSearchValue,
  createEngagementUserMutation,
  deleteEngagementUserMutation,
  updateEngagementUserMutation,
  deleteEngagementMutation,
  addHoursToEngagementUserMutation,
  isOpen,
  selectedTab,
  handleChangeCustomerOrPM,
}) => {
  const [editableFields, setEditableFields] = useState([]);
  const [selectedServiceProvider, setSelectedServiceProvider] = useState(null);

  const { showDeleteModal, showConfirmActionModal } = useContext(UiContext);
  const isDesktop = useCheckDesktopScreen();
  const { currentUser, isAdmin, isProjectManager, isServiceProvider } =
    useUser();

  const { id, title, description, status } = engagement;
  const { customer } = engagement;
  const { hires } = engagement;
  const { projectManager } = engagement;

  const currentServiceProvider = useMemo(() => {
    return engagement?.hires.find(
      (serviceProvider) => serviceProvider.userId === currentUser?.id
    );
  }, [engagement, currentUser]);

  const handleEditToggle = useCallback(() => {
    setEditableFields((prevState) => {
      if (prevState.length) {
        return [];
      }

      return Object.values(EDITABLE_FIELDS);
    });
  }, []);

  const handleTitleUpdate = useCallback(
    (titleValue) => {
      handleUpdate({ id, title: titleValue });
      setEditableFields((prevState) =>
        prevState.filter((field) => field !== EDITABLE_FIELDS.TITLE)
      );
    },
    [handleUpdate]
  );

  const handleDescriptionUpdate = useCallback(
    (descriptionValue) => {
      handleUpdate({ id, description: descriptionValue });
      setEditableFields((prevState) =>
        prevState.filter((field) => field !== EDITABLE_FIELDS.DESCRIPTION)
      );
    },
    [handleUpdate]
  );

  const handleServiceProviderSelect = useCallback(
    (serviceProvider) => {
      setSelectedServiceProvider(serviceProvider);
    },
    [setSelectedServiceProvider]
  );

  const handleCreateEngagementUser = useCallback(
    ({ clientRate, serviceProviderRate, maxHours, responsibilities }) => {
      createEngagementUserMutation({
        id,
        userId: selectedServiceProvider.id,
        clientRate,
        serviceProviderRate,
        maxHours,
        responsibilities,
      });

      setSelectedServiceProvider(null);
    },
    [createEngagementUserMutation, selectedServiceProvider, id]
  );

  const handleDeleteEngagementUser = useCallback(
    (engagementUserId) => {
      deleteEngagementUserMutation({
        id: engagementUserId,
      });
    },
    [deleteEngagementUserMutation]
  );

  const handleUpdateEngagementUser = useCallback(
    ({
      id: engagementUserId,
      clientRate,
      serviceProviderRate,
      maxHours,
      responsibilities,
      status: engagementUserStatus,
      hours,
    }) => {
      updateEngagementUserMutation({
        id: engagementUserId,
        clientRate,
        serviceProviderRate,
        maxHours,
        responsibilities,
        status: engagementUserStatus,
        hours,
      });
    },
    [updateEngagementUserMutation]
  );

  const handleAddHoursToEngagementUser = useCallback(
    (hours) => {
      const formattedHours = Math.floor(hours);
      const formattedMinutes = Math.round((hours % 1) * 60);

      showConfirmActionModal({
        title: `You are about to log ${formattedHours} hours ${
          formattedMinutes ? `${formattedMinutes} minutes` : ''
        }, are you sure?`,
        handleSubmit: () => {
          addHoursToEngagementUserMutation({
            id: currentServiceProvider.id,
            hours,
          });
        },
      });
    },
    [
      addHoursToEngagementUserMutation,
      currentServiceProvider,
      showConfirmActionModal,
    ]
  );

  const handleEngagementDelete = useCallback(() => {
    showDeleteModal({
      data: engagement,
      handleDelete: () => deleteEngagementMutation(engagement.id),
      type: 'engagement',
    });
  }, [showDeleteModal, engagement, deleteEngagementMutation]);

  const filteredServiceProviders = useMemo(() => {
    return serviceProviders?.filter((serviceProvider) => {
      const isCustomer = serviceProvider.id === customer.id;
      const isSelected = selectedServiceProvider?.id === serviceProvider.id;
      const isAlreadyHired = hires.find(
        (hire) => hire.user.id === serviceProvider.id
      );

      return !isCustomer && !isSelected && !isAlreadyHired;
    });
  }, [serviceProviders, customer, hires, selectedServiceProvider]);

  const isEngagementEditing = editableFields.length > 0;
  const isTitleEditable = editableFields.includes(EDITABLE_FIELDS.TITLE);
  const isDescriptionEditable = editableFields.includes(
    EDITABLE_FIELDS.DESCRIPTION
  );

  const isEditButtonVisible = isOpen && (isAdmin || isProjectManager);
  const isDeleteButtonVisible = isOpen && (isAdmin || isProjectManager);
  const isAddHiresDropdownVisible = isOpen && (isAdmin || isProjectManager);
  const isServiceProviderView =
    selectedTab === ENGAGEMENTS_SWITCH_OPTIONS.CUSTOMERS && isServiceProvider;
  const isHiresListVisible = !isServiceProviderView;

  return (
    <li
      className={cn(classes.EngagementsItem, {
        [classes.open]: isOpen,
        [classes.serviceProvider]: isServiceProviderView,
      })}
    >
      <div className={cn(classes.row, classes.top)}>
        <Title
          engagementId={id}
          title={title}
          handleUpdate={handleTitleUpdate}
          isEditable={isTitleEditable}
          isEngagementEditing={isEngagementEditing}
          isEngagementOpen={isOpen}
          isAccessible={!isServiceProviderView}
          selectedTab={selectedTab}
        />
        <div className={cn(classes.row, classes.buttons)}>
          <Status
            value={
              isServiceProviderView ? currentServiceProvider?.status : status
            }
            valuesToShow={
              isServiceProviderView
                ? ENGAGEMENT_USER_STATUS_VALUES
                : ENGAGEMENT_STATUS_VALUES
            }
            valueColors={
              isServiceProviderView
                ? ENGAGEMENT_USER_STATUS_COLORS
                : ENGAGEMENT_STATUS_COLORS
            }
            handleUpdate={(updatedStatus) =>
              handleUpdate({ id, status: updatedStatus })
            }
            hasEditPermission={isAdmin || isProjectManager}
          />

          {isEditButtonVisible && (
            <RoundButtonWithIcon
              onClick={handleEditToggle}
              classnames={[
                classes.actionButton,
                isEngagementEditing && classes.active,
              ]}
              icon={editIcon}
              iconAlt="Edit engagement"
            />
          )}

          {isDeleteButtonVisible && (
            <RoundButtonWithIcon
              onClick={handleEngagementDelete}
              classnames={[classes.actionButton]}
              icon={deleteIcon}
            />
          )}
        </div>
      </div>

      <div className={cn(classes.row, classes.middle)}>
        <div className={cn(classes.row, classes.customerContainer)}>
          <Customer
            customer={customer}
            isEngagementOpen={isOpen}
            isServiceProviderView={isServiceProviderView}
            onEditClick={() => handleChangeCustomerOrPM(USER_ROLES.CUSTOMER)}
          />

          {isServiceProviderView && !isDesktop && (
            <ProjectManager projectManager={projectManager} />
          )}

          {isAddHiresDropdownVisible && (
            <div className={classes.addHiresContainer}>
              <AddHiresDropdown
                label="Add hire"
                items={filteredServiceProviders}
                selectedItem={selectedServiceProvider}
                onItemClick={handleServiceProviderSelect}
                onResetSelectedItem={() => setSelectedServiceProvider(null)}
                searchValue={serviceProviderSearchValue}
                onSearch={handleServiceProviderSearchValueChange}
                clearSearchValue={clearServiceProviderSearchValue}
                onSubmit={handleCreateEngagementUser}
              />
            </div>
          )}
        </div>

        {isServiceProviderView && (
          <div className={cn(classes.row, classes.ratesContainer)}>
            <Rate title="Your Billable Rate">${currentUser?.rate} / hour</Rate>

            <Rate title="Max Billable Hours">
              {currentServiceProvider?.maxHours} / week
            </Rate>

            <TotalHours hours={currentServiceProvider.totalHours} />
          </div>
        )}

        <Description
          description={
            isServiceProviderView
              ? currentServiceProvider?.responsibilities
              : description
          }
          handleUpdate={handleDescriptionUpdate}
          isEditable={isDescriptionEditable}
          isEngagementEditing={isEngagementEditing}
          isEngagementOpen={isOpen}
          isServiceProviderView={isServiceProviderView}
        />
      </div>

      {isHiresListVisible && (
        <HiresList
          hires={hires}
          handleDeleteEngagementUser={handleDeleteEngagementUser}
          handleUpdateEngagementUser={handleUpdateEngagementUser}
          isEngagementOpen={isOpen}
        />
      )}

      {isDesktop && (
        <ProjectManager
          projectManager={projectManager}
          isEngagementOpen={isOpen}
          onEditClick={() =>
            handleChangeCustomerOrPM(USER_ROLES.PROJECT_MANAGER)
          }
        />
      )}

      {isServiceProviderView && (
        <div className={classes.billingContainer}>
          <LogHours
            className={classes.logHours}
            onAddHours={handleAddHoursToEngagementUser}
            serviceProviderStatus={currentServiceProvider.status}
          />
          <div className={classes.billingRateInfo}>
            <InfoIcon className={classes.infoIcon} />
            <p className={classes.text}>
              Note: The customer will see a different billing rate based on any
              LevelDo markup percentages and fees in accordance with the
              LevelDo.Pros service agreement. Negotiating or discussing billing
              rates outside of the agreement with customers is strictly
              prohibited. Direct all customers to contact LevelDo. Pros support
              to discuss engagement billing & rates.
            </p>
          </div>
        </div>
      )}
    </li>
  );
};

export default EngagementsItem;
