import React, { useCallback, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import cn from 'classnames';

import checkmark from '../../../../assets/icons/checkmark.svg';

import Input from '../../../UI/Input';
import RoundButtonWithIcon from '../../../UI/Buttons/RoundButtonWithIcon';
import classes from './styles.module.scss';

const Title = ({
  engagementId,
  title,
  isEditable,
  handleUpdate,
  isEngagementEditing,
  isEngagementOpen,
  isAccessible,
  selectedTab,
}) => {
  const [newTitle, setNewTitle] = useState(title);

  const handleTitleChange = useCallback((event) => {
    const { value } = event.target;

    setNewTitle(value);
  }, []);

  useEffect(() => {
    if (!isEngagementEditing) {
      setNewTitle(title);
    }
  }, [isEngagementEditing, title]);

  let content;

  if (isEditable) {
    content = (
      <div className={classes.inputContainer}>
        <Input
          value={newTitle}
          onChange={handleTitleChange}
          classnames={[classes.titleInput]}
        />
        <RoundButtonWithIcon
          onClick={() => handleUpdate(newTitle)}
          classnames={[classes.titleButton]}
          icon={checkmark}
          iconAlt="Update title"
        />
      </div>
    );
  } else if (isAccessible && !isEngagementOpen) {
    content = (
      <Link
        to={`/engagements/${engagementId}${
          selectedTab ? `?tab=${selectedTab}` : ''
        }`}
        className={cn(classes.title, classes.link)}
      >
        {title}
      </Link>
    );
  } else {
    content = <h3 className={classes.title}>{title}</h3>;
  }

  return (
    <div
      className={cn(classes.Title, {
        [classes.open]: isEngagementOpen,
      })}
    >
      {content}
    </div>
  );
};

export default Title;
