import React from 'react';

import cn from 'classnames';

import classes from './styles.module.scss';

const Switch = ({
  customerOption,
  providerOption,
  onChange,
  selectedOption,
  userRoles,
}) => {
  const { isCustomer, isServiceProvider, isAdmin, isProjectManager } =
    userRoles;
  const shouldShowCustomerButton = isCustomer || isAdmin || isProjectManager;
  const shouldShowServiceProviderButton =
    isServiceProvider || isAdmin || isProjectManager;
  return (
    <div className={classes.Switch}>
      {shouldShowCustomerButton && (
        <button
          onClick={() => onChange(customerOption)}
          key={customerOption}
          className={cn(classes.option, {
            [classes.selected]: selectedOption === customerOption,
          })}
          type="button"
        >
          {customerOption}
        </button>
      )}

      {shouldShowServiceProviderButton && (
        <button
          onClick={() => onChange(providerOption)}
          key={providerOption}
          className={cn(classes.option, {
            [classes.selected]: selectedOption === providerOption,
          })}
          type="button"
        >
          {providerOption}
        </button>
      )}
    </div>
  );
};

export default Switch;
