import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';

import deleteIcon from '../../assets/icons/trash-blue.svg';

import { UiContext } from '../../context/UiContext';
import { STATIC_URL, USER_ROLES } from '../../constants/main';
import UserService from '../../services/UserService';
import Input from '../UI/Input';
import PrimaryButton from '../UI/Buttons/PrimaryButton';
import ErrorMessage from '../UI/ErrorMessage';
import AvatarUpload from './AvatarUpload';
import RoleCheckboxes from './RoleCheckboxes';
import ServiceProviderDetails from './ServiceProviderDetails';
import PasswordInputs from './PasswordInputs';
import classes from './styles.module.scss';

const TYPES = {
  CREATE: 'create',
  EDIT: 'edit',
};

const validationSchema = yup.object({
  fullName: yup.string().trim().required('Full Name is required.'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address.')
    .required('Email is required.'),
  companyName: yup.string().trim(),
  rate: yup.number().when('roles', {
    is: (roles) => roles.includes(USER_ROLES.SERVICE_PROVIDER),
    then: (schema) => schema.required('Standard Rate is required.'),
  }),
  roles: yup
    .array()
    .min(1, 'At least one role is required.')
    .of(yup.string().required())
    .required(),
  password: yup
    .string()
    .trim()
    .min(8, 'Password must be at least 8 characters.'),
  confirmPassword: yup.string().when('password', {
    is: (password) => password?.length > 0,
    then: (schema) =>
      schema
        .required('Confirm Password is required.')
        .oneOf([yup.ref('password')], 'Passwords must match.'),
  }),
});

const CreateOrEditUserForm = () => {
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState('');
  const [editorHtml, setEditorHtml] = useState('');

  const queryClient = useQueryClient();
  const history = useHistory();
  const { id } = useParams();
  const {
    showDeleteModal,
    showErrorModal,
    showEditorModal,
    isEditorModalOpen,
  } = useContext(UiContext);

  const type = id ? TYPES.EDIT : TYPES.CREATE;

  const { data: user } = useQuery('user', () => UserService.getUserById(id), {
    enabled: !!id,
  });

  const { mutate: createUserMutation } = useMutation(UserService.createUser, {
    onSuccess: () => {
      history.push('/users-management');
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { mutate: updateUserMutation } = useMutation(UserService.updateUser, {
    onSuccess: () => {
      if (isEditorModalOpen) {
        return;
      }

      history.push('/users-management');
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { mutate: deleteUserMutation } = useMutation(UserService.deleteUser, {
    onSuccess: () => {
      history.push('/users-management');
    },
    onError: (error) => {
      showErrorModal({
        message: error.response.data.message,
      });
    },
  });

  useEffect(() => {
    if (type === TYPES.CREATE && user) {
      queryClient.setQueryData('user', null);
    }
  }, [queryClient, type, user]);

  useEffect(() => {
    if (user?.avatarPath) {
      setAvatarPreview(STATIC_URL + user.avatarPath);
    } else {
      setAvatarPreview('');
    }

    if (user?.details) {
      setEditorHtml(user.details);
    } else {
      setEditorHtml('');
    }
  }, [user]);

  const handleSubmit = useCallback(
    ({ fullName, email, companyName, rate, roles, password }) => {
      if (type === TYPES.EDIT) {
        updateUserMutation({
          id,
          name: fullName,
          email,
          companyName,
          rate,
          roles: JSON.stringify(roles),
          avatar: avatarFile,
          details: editorHtml,
          password,
        });
      } else if (type === TYPES.CREATE) {
        createUserMutation({
          name: fullName,
          email,
          companyName,
          rate,
          roles: JSON.stringify(roles),
          avatar: avatarFile,
          details: editorHtml,
        });
      }
    },
    [createUserMutation, updateUserMutation, id, type, avatarFile, editorHtml]
  );

  const initialValues = useMemo(
    () => ({
      fullName: user?.name || '',
      email: user?.email || '',
      companyName: user?.companyName || '',
      rate: user?.rate || '',
      roles: user?.roles || [],
      password: '',
    }),
    [user, type]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleAvatarUploadChange = useCallback((event) => {
    const file = event.target.files[0];

    if (file) {
      setAvatarFile(file);

      const reader = new FileReader();

      reader.onload = (e) => {
        setAvatarPreview(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  }, []);

  const handleSelectedRolesChange = useCallback(
    (event) => {
      const { checked, value } = event.target;

      if (checked) {
        formik.setFieldValue('roles', [...formik.values.roles, value]);
      } else {
        const updatedRoles = formik.values.roles.filter(
          (role) => role !== value
        );
        formik.setFieldValue('roles', updatedRoles);
      }
    },
    [formik]
  );

  const handleEditorChange = useCallback(() => {
    showEditorModal({
      html: editorHtml,
      setHtml: setEditorHtml,
      handleUpdate: (details) => {
        if (type === TYPES.CREATE) {
          return;
        }

        updateUserMutation({
          id,
          name: user.name,
          email: user.email,
          companyName: user.companyName,
          rate: user.rate,
          roles: JSON.stringify(user.roles),
          avatar: null,
          details,
        });
      },
    });
  }, [
    showEditorModal,
    editorHtml,
    setEditorHtml,
    updateUserMutation,
    id,
    user,
  ]);

  const handlePublicProfileClick = useCallback(() => {
    window.open(`/public-profile/${user?.id}`, '_blank');
  }, [user]);

  const handleUserDelete = useCallback(() => {
    showDeleteModal({
      data: user,
      handleDelete: () => deleteUserMutation(user.id),
      type: 'user',
    });
  }, [showDeleteModal, deleteUserMutation, user]);

  const title = type === TYPES.EDIT ? 'Edit User' : 'Create User';
  const submitButtonText = type === TYPES.EDIT ? 'Save' : 'Create';
  const isServiceProviderSelected = formik.values.roles.includes(
    USER_ROLES.SERVICE_PROVIDER
  );

  return (
    <div className={classes.CreateOrEditUserForm}>
      <form className={classes.form}>
        <h1 className={classes.title}>{title}</h1>
        <div className={classes.formContainer}>
          <div className={classes.avatarAndRolesContainer}>
            <AvatarUpload
              avatarPreview={avatarPreview}
              handleAvatarUploadChange={handleAvatarUploadChange}
            />
            <RoleCheckboxes
              handleSelectedRolesChange={handleSelectedRolesChange}
              roles={formik.values.roles}
              isTouched={formik.touched.roles}
              error={formik.errors.roles}
            />
          </div>

          <div className={classes.inputs}>
            <div className={classes.inputContainer}>
              <Input
                value={formik.values.fullName}
                name="fullName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                classnames={[classes.input]}
                placeholder="Full Name"
                hasError={formik.touched.fullName && formik.errors.fullName}
              />
              {formik.touched.fullName && formik.errors.fullName && (
                <ErrorMessage message={formik.errors.fullName} />
              )}
            </div>

            <div className={classes.inputContainer}>
              <Input
                value={formik.values.email}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                classnames={[classes.input]}
                placeholder="Email"
                hasError={formik.touched.email && formik.errors.email}
              />
              {formik.touched.email && formik.errors.email && (
                <ErrorMessage message={formik.errors.email} />
              )}
            </div>

            <div className={classes.inputContainer}>
              <Input
                value={formik.values.companyName}
                name="companyName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                classnames={[classes.input]}
                placeholder="Company (optional)"
                hasError={
                  formik.touched.companyName && formik.errors.companyName
                }
              />
              {formik.touched.companyName && formik.errors.companyName && (
                <ErrorMessage message={formik.errors.companyName} />
              )}
            </div>

            {type === TYPES.EDIT && (
              <PasswordInputs
                isPasswordVisible
                passwordValue={formik.values.password}
                confirmPasswordValue={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isPasswordTouched={formik.touched.password}
                isConfirmPasswordTouched={formik.touched.confirmPassword}
                passwordError={formik.errors.password}
                confirmPasswordError={formik.errors.confirmPassword}
              />
            )}
          </div>
        </div>

        {isServiceProviderSelected && (
          <ServiceProviderDetails
            rate={formik.values.rate}
            onRateChange={formik.handleChange}
            onRateBlur={formik.handleBlur}
            isRateTouched={formik.touched.rate}
            rateErrors={formik.errors.rate}
            onEditorChange={handleEditorChange}
            onPublicProfileClick={handlePublicProfileClick}
            isCustomLinkDisabled={type === TYPES.CREATE}
          />
        )}
      </form>

      {type === TYPES.EDIT && (
        <button
          onClick={handleUserDelete}
          type="button"
          className={classes.deleteButton}
        >
          <img src={deleteIcon} alt="Delete" />
          Delete user
        </button>
      )}

      <PrimaryButton
        onClick={formik.handleSubmit}
        iconName="arrow"
        classnames={[classes.createButton]}
      >
        {submitButtonText}
      </PrimaryButton>
    </div>
  );
};

export default CreateOrEditUserForm;
